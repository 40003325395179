import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { FaTrash } from "react-icons/fa";

const ImageUpload = ({ selectedFile, onFileChange }) => {
  const [file, setFile] = useState(selectedFile || null);
  const [previewUrl, setPreviewUrl] = useState(null);

  // Generate and clean up object URL
  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [file]);

  // Image validation logic
  const validateImage = (file) => {
    const validTypes = ["image/png", "image/jpeg"];
    const maxSize = 5 * 1024 * 1024; // 5 MB
    if (!validTypes.includes(file.type)) {
      alert("Invalid file type. Only PNG and JPEG are allowed.");
      return false;
    }
    if (file.size > maxSize) {
      alert("File size exceeds 5MB limit.");
      return false;
    }
    return true;
  };

  // Handle file drop
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const selected = acceptedFiles[0];
        if (validateImage(selected)) {
          setFile(selected);
          if (onFileChange) onFileChange(selected);
        }
      }
    },
    [onFileChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop,
  });

  const removeImage = () => {
    setFile(null);
    setPreviewUrl(null);
    if (onFileChange) onFileChange(null);
  };

  return (
    <div className="flex flex-col space-y-4 w-full h-56">
      {/* Drop Area */}
      <div
        {...getRootProps()}
        className={`w-96 h-48 flex flex-col justify-center items-center border-2 rounded-lg cursor-pointer transition-all ${
          isDragActive
            ? "border-blue-500 bg-blue-100"
            : "border-dashed border-gray-400 hover:bg-gray-100"
        }`}
        aria-label="Image upload drop area"
      >
        <input {...getInputProps()} />
        {!file ? (
          <p className="text-gray-500 text-center">
            {isDragActive
              ? "Drop the file here..."
              : "Drag & Drop an image here, or click to select"}
          </p>
        ) : (
          <div className="relative flex flex-col items-center">
            <div className="w-24 h-24 border-2 border-gray-300 rounded-md overflow-hidden shadow-md">
              <img
                src={previewUrl}
                alt="Uploaded Preview"
                className="h-full object-cover"
              />
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                removeImage();
              }}
              className="mt-2 bg-gray-700 text-white px-3 py-1 rounded hover:bg-red-600 transition"
              aria-label="Remove Image"
            >
              <FaTrash className="inline" /> Remove
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

ImageUpload.propTypes = {
  selectedFile: PropTypes.object,
  onFileChange: PropTypes.func.isRequired,
};

export default ImageUpload;
