const initialState = {
  left_screen: { text: "", isValid: false },
  right_screen: { text: "", isValid: false },
};

const jsonValidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LEFT_TEXT":
      return {
        ...state,
        left_screen: {
          ...state.left_screen,
          text: action.payload.text || "",
          isValid:
            action.payload.isValid !== undefined
              ? action.payload.isValid
              : state.left_screen.isValid,
        },
      };

    case "SET_RIGHT_TEXT":
      return {
        ...state,
        right_screen: {
          ...state.right_screen,
          text: action.payload.text || "",
          isValid:
            action.payload.isValid !== undefined
              ? action.payload.isValid
              : state.right_screen.isValid,
        },
      };

    default:
      return state;
  }
};

export default jsonValidateReducer;
