import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextHeader from "./dashboard/TextHeader";

function TextDiffChecker() {
  const [oldText, setOldText] = useState("");
  const [newText, setNewText] = useState("");
  const [diffLines, setDiffLines] = useState([]);

  // Compute diff
  const computeLineDiff = (oldText, newText) => {
    const oldLines = oldText.split("\n");
    const newLines = newText.split("\n");

    const diff = [];
    const maxLines = Math.max(oldLines.length, newLines.length);

    for (let i = 0; i < maxLines; i++) {
      const oldLine = oldLines[i] || "";
      const newLine = newLines[i] || "";

      if (oldLine === newLine) {
        diff.push({
          type: "unchanged",
          oldLine: oldLine,
          newLine: newLine,
        });
      } else {
        diff.push({
          type: "changed",
          oldLine: oldLine,
          newLine: newLine,
        });
      }
    }

    return diff;
  };

  const computeWordDiff = (oldLine, newLine) => {
    const oldWords = oldLine.split(" ");
    const newWords = newLine.split(" ");

    const wordDiff = [];
    const maxWords = Math.max(oldWords.length, newWords.length);

    for (let i = 0; i < maxWords; i++) {
      const oldWord = oldWords[i] || "";
      const newWord = newWords[i] || "";

      if (oldWord === newWord) {
        wordDiff.push({ type: "unchanged", oldWord, newWord });
      } else {
        wordDiff.push({
          type: "changed",
          oldWord: oldWord || null,
          newWord: newWord || null,
        });
      }
    }

    return wordDiff;
  };

  const handleDiffCheck = () => {
    if (!oldText.trim() || !newText.trim()) {
      toast.error("Both text areas must be filled!", { autoClose: 1000 });
      return;
    }

    const diff = computeLineDiff(oldText, newText).map((lineDiff) => {
      if (lineDiff.type === "changed") {
        return {
          ...lineDiff,
          wordDiff: computeWordDiff(lineDiff.oldLine, lineDiff.newLine),
        };
      }
      return lineDiff;
    });

    setDiffLines(diff);
  };

  const renderLineDiff = (lineDiff, index) => {
    if (lineDiff.type === "unchanged") {
      return (
        <div key={index} className="flex">
          <div className="w-1/2 bg-gray-100 p-2">
            <span className="text-sm text-gray-700">{index + 1}</span>{" "}
            <span className="text-gray-900">{lineDiff.oldLine}</span>
          </div>
          <div className="w-1/2 bg-gray-100 p-2">
            <span className="text-sm text-gray-700">{index + 1}</span>{" "}
            <span className="text-gray-900">{lineDiff.newLine}</span>
          </div>
        </div>
      );
    }

    return (
      <div key={index} className="flex">
        <div className="w-1/2 bg-white p-2">
          <span className="text-sm text-gray-700">{index + 1}</span>{" "}
          {lineDiff.wordDiff.map((word, i) =>
            word.oldWord ? (
              <span
                key={i}
                className={`${
                  word.type === "changed" ? "bg-red-200 text-red-600" : ""
                } px-1`}
              >
                {word.oldWord}
              </span>
            ) : null
          )}
        </div>
        <div className="w-1/2 bg-white p-2">
          <span className="text-sm text-gray-700">{index + 1}</span>{" "}
          {lineDiff.wordDiff.map((word, i) =>
            word.newWord ? (
              <span
                key={i}
                className={`${
                  word.type === "changed" ? "bg-green-200 text-green-600" : ""
                } px-1`}
              >
                {word.newWord}
              </span>
            ) : null
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <TextHeader />
      <div className="p-8 font-sans">
        <ToastContainer />
        <div className="flex flex-col md:flex-row justify-between gap-6">
          <div className="relative w-full md:w-1/2">
            <label className="block text-gray-700 font-semibold mb-2">
              Old Text
            </label>
            <textarea
              value={oldText}
              onChange={(e) => setOldText(e.target.value)}
              placeholder="Enter old text here"
              className="w-full p-4 border rounded-lg shadow-sm resize-none focus:outline-none focus:ring-2 focus:ring-purple-500 bg-white"
              rows={10}
            />
          </div>
          <div className="relative w-full md:w-1/2">
            <label className="block text-gray-700 font-semibold mb-2">
              New Text
            </label>
            <textarea
              value={newText}
              onChange={(e) => setNewText(e.target.value)}
              placeholder="Enter new text here"
              className="w-full p-4 border rounded-lg shadow-sm resize-none focus:outline-none focus:ring-2 focus:ring-purple-500 bg-white"
              rows={10}
            />
          </div>
        </div>

        <div className="flex justify-center mt-6">
          <button
            onClick={handleDiffCheck}
            className="py-3 px-6 bg-purple-500 text-white font-semibold rounded-lg shadow-lg hover:bg-purple-600 transition duration-300"
          >
            Check Difference
          </button>
        </div>

        <div className="mt-10">
          {diffLines.map((lineDiff, index) => renderLineDiff(lineDiff, index))}
        </div>
      </div>
    </>
  );
}

export default TextDiffChecker;
