import HexCraftDashboard from "../dashboard/HexCraftDashboard";
import { binaryConverters } from "../staticData";
function BinaryConversion() {
  return (
    <>
      <HexCraftDashboard
        config={{ id: "binary", dropdowns: binaryConverters }}
      />
    </>
  );
}
export default BinaryConversion;
