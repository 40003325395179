import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import AceEditor from "react-ace";

// Import ACE themes and modes
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-json";

// Default configuration for the editor
const defaultConfig = {
  mode: "json",
  theme: "github",
  fontSize: 16,
  width: "40%",
  height: "800px",
  textColor: "black", // Purple color
  showGutter: true,
  showPrintMargin: false,
  enableBasicAutocompletion: false,
  enableLiveAutocompletion: false,
  enableSnippets: false,
  readOnly: false,
};

const JsonTextEditor = ({ jsonInput, onChange, config }) => {
  // Merge config with defaults
  const mergedConfig = { ...defaultConfig, ...config };

  const [content, setContent] = useState(jsonInput || "");

  // Update content if jsonInput prop changes
  useEffect(() => {
    setContent((prevContent) =>
      prevContent !== jsonInput ? jsonInput : prevContent
    );
  }, [jsonInput]);

  // Handle content changes in the editor
  const handleEditorChange = useCallback(
    (newContent) => {
      setContent(newContent);
      if (onChange) {
        onChange(newContent);
      }
    },
    [onChange]
  );

  return (
    <AceEditor
      mode={mergedConfig.mode}
      theme={mergedConfig.theme}
      value={content}
      onChange={handleEditorChange}
      name="json-text-editor"
      fontSize={mergedConfig.fontSize}
      width={mergedConfig.width}
      height={mergedConfig.height}
      showGutter={mergedConfig.showGutter}
      showPrintMargin={mergedConfig.showPrintMargin}
      highlightActiveLine={!mergedConfig.readOnly}
      readOnly={mergedConfig.readOnly}
      setOptions={{
        useWorker: false,
        enableBasicAutocompletion: mergedConfig.enableBasicAutocompletion,
        enableLiveAutocompletion: mergedConfig.enableLiveAutocompletion,
        enableSnippets: mergedConfig.enableSnippets,
      }}
      style={{
        color: mergedConfig.textColor,
        backgroundColor: "white",
        border: "1px solid #A29CF4", // Purple border
        borderRadius: "8px",
        padding: "8px",
      }}
    />
  );
};

// Prop types for type checking and validation
JsonTextEditor.propTypes = {
  jsonInput: PropTypes.string,
  onChange: PropTypes.func,
  config: PropTypes.shape({
    mode: PropTypes.string,
    theme: PropTypes.string,
    fontSize: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    textColor: PropTypes.string,
    showGutter: PropTypes.bool,
    showPrintMargin: PropTypes.bool,
    enableBasicAutocompletion: PropTypes.bool,
    enableLiveAutocompletion: PropTypes.bool,
    enableSnippets: PropTypes.bool,
    readOnly: PropTypes.bool,
  }),
};

JsonTextEditor.defaultProps = {
  jsonInput: "",
  onChange: () => {},
  config: {},
};

export default React.memo(JsonTextEditor);
