import HexCraftDashboard from "../dashboard/HexCraftDashboard";
import { hexadecimalConverters } from "../staticData";
function HexaDecimalConversion() {
  return (
    <>
      <HexCraftDashboard
        config={{ id: "hexadecimal", dropdowns: hexadecimalConverters }}
      />
    </>
  );
}
export default HexaDecimalConversion;
