import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AnalysisResult = ({ analysis, onClickCategory }) => {
  const chartData = analysis
    ? {
        labels: ["Positive", "Negative", "Neutral"],
        datasets: [
          {
            label: "Word Count",
            data: [
              analysis.positive.length,
              analysis.negative.length,
              analysis.tokens.length -
                analysis.positive.length -
                analysis.negative.length, // Neutral = Total Tokens - Positive - Negative
            ],
            backgroundColor: ["#8BC34A", "#E91E63", "#FFC107"],
            borderWidth: 1,
          },
        ],
      }
    : null;

  const handleClick = (event, chartElement) => {
    if (chartElement.length > 0) {
      const index = chartElement[0].index;
      const category = chartData.labels[index].toLowerCase(); // Get clicked category
      onClickCategory(category); // Trigger the category click handler
    }
  };

  return (
    <div className="mt-6">
      <h2 className="text-xl font-semibold text-purple-700 mb-2">Analysis Results</h2>
      <div className="bg-purple-50 p-4 rounded-lg">
        <p className="text-purple-600">
          <strong>Overall Score:</strong> {analysis.score}
        </p>
        <p className="text-purple-600">
          <strong>Comparative Score:</strong> {analysis.comparative.toFixed(2)}
        </p>
      </div>

      {/* Chart */}
      {chartData && (
        <div className="mt-6">
          <Bar
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: { display: true },
                tooltip: { enabled: true },
              },
              scales: {
                x: {
                  type: "category",
                  ticks: { color: "#7B1FA2" }, // Purple axis labels
                },
                y: {
                  type: "linear",
                  beginAtZero: true,
                  ticks: { color: "#7B1FA2" }, // Purple axis labels
                },
              },
              onClick: handleClick, // Attach click handler
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AnalysisResult;
