import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HexCraftHeader from "./HexCraftHeader";
import { conversionExampleData } from "../staticData";
import { dropDownStyle } from "../../../style/commonStyle";

// Helper Function: Validate Input
const validateInput = (userInput, targetAlgo) => {
  if (!userInput.trim()) return false;

  const validationPatterns = {
    binary: /^[01]+$/,
    hex: /^[0-9a-fA-F]+$/,
    decimal: /^\d+$/,
    octal: /^[0-7]+$/,
    text: () => typeof userInput === "string",
  };

  switch (targetAlgo) {
    case "btx":
    case "btd":
    case "btat":
    case "bto":
      return validationPatterns.binary.test(userInput);
    case "htb":
    case "htd":
    case "htas":
      return validationPatterns.hex.test(userInput);
    case "dth":
    case "dto":
    case "dtb":
      return validationPatterns.decimal.test(userInput);
    case "atb":
    case "atd":
    case "ath":
      return validationPatterns.text(userInput);
    case "otd":
    case "otb":
      return validationPatterns.octal.test(userInput);
    default:
      return false;
  }
};

// Helper Function: Perform Conversion
const algoConversion = (userInput, targetAlgo) => {
  try {
    switch (targetAlgo) {
      case "btx":
        return parseInt(userInput, 2).toString(16).toUpperCase();
      case "btd":
        return parseInt(userInput, 2).toString(10);
      case "btat":
        if (userInput.length % 8 !== 0) {
          throw new Error("Binary input must be a multiple of 8 for ASCII.");
        }
        return userInput
          .match(/.{8}/g)
          .map((bin) => String.fromCharCode(parseInt(bin, 2)))
          .join("");
      case "bto":
        return parseInt(userInput, 2).toString(8);
      case "htb":
        return parseInt(userInput, 16).toString(2);
      case "htd":
        return parseInt(userInput, 16).toString(10);
      case "htas":
        if (userInput.length % 2 !== 0) {
          throw new Error("Hexadecimal input must be even for ASCII.");
        }
        return userInput
          .match(/.{2}/g)
          .map((hex) => String.fromCharCode(parseInt(hex, 16)))
          .join("");
      case "dth":
        return parseInt(userInput, 10).toString(16).toUpperCase();
      case "dto":
        return parseInt(userInput, 10).toString(8);
      case "dtb":
        return parseInt(userInput, 10).toString(2);
      case "atb":
        return userInput
          .split("")
          .map((char) => char.charCodeAt(0).toString(2).padStart(8, "0"))
          .join(" ");
      case "atd":
        return userInput.split("").map((char) => char.charCodeAt(0)).join(" ");
      case "ath":
        return userInput
          .split("")
          .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
          .join("");
      case "otd":
        return parseInt(userInput, 8).toString(10);
      case "otb":
        return parseInt(userInput, 8).toString(2);
      default:
        throw new Error("Unsupported conversion algorithm.");
    }
  } catch (error) {
    throw new Error(`Conversion failed: ${error.message}`);
  }
};

function HexCraftDashboard({ config }) {
  const { id = "default-id", dropdowns = [] } = config || {};
  const [targetAlgo, setTargetAlgo] = useState(dropdowns[0]?.id || "");
  const [inputValue, setInputValue] = useState("");
  const [outputValue, setOutputValue] = useState("");
  const [example, setExample] = useState(conversionExampleData[targetAlgo] || {});

  // Update example when targetAlgo changes
  useEffect(() => {
    setExample(conversionExampleData[targetAlgo] || {});
  }, [targetAlgo]);

  const selectedConverter = dropdowns.find((item) => item.id === targetAlgo);

  const [fromLabel, toLabel] = useMemo(() => {
    if (selectedConverter?.name) {
      return selectedConverter.name.split(" To ");
    }
    return ["Input", "Output"];
  }, [selectedConverter]);

  const handleConvert = () => {
    if (!validateInput(inputValue, targetAlgo)) {
      toast.error("Invalid input for the selected conversion.");
      setOutputValue("");
      return;
    }

    try {
      const result = algoConversion(inputValue, targetAlgo);
      toast.success("Conversion successful!");
      setOutputValue(result);
    } catch (error) {
      toast.error(error.message);
      setOutputValue("");
    }
  };

  return (
    <>
      <HexCraftHeader />
      <div className="flex flex-col items-center min-h-screen bg-white">
        <ToastContainer />
        {/* Dropdown Section */}
        <div className="w-full max-w-xs mt-8">
          <label
            htmlFor={id}
            className="block text-sm font-medium text-purple-700 mb-2"
          >
            Choose Conversion
          </label>
          <select
            id={id}
            value={targetAlgo}
            onChange={(e) => setTargetAlgo(e.target.value)}
            className={`${dropDownStyle}`}
          >
            {dropdowns.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
        </div>

        {/* Input and Output Section */}
        <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg mt-10 p-6 flex flex-col space-y-6">
          <h2 className="text-lg font-semibold text-purple-700">
            {example.title}
          </h2>
          <p className="text-sm ">{example.description}</p>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{ __html: example.example }}
          ></div>
        </div>

        {/* Input and Output Section */}
        <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg mt-10 p-6 flex flex-col space-y-6">
          <div className="flex flex-wrap space-x-6">
            {/* Input Field */}
            <div className="flex-1">
              <label
                htmlFor="inputField"
                className="block text-sm font-medium text-purple-700 mb-2"
              >
                {fromLabel}
              </label>
              <input
                id="inputField"
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter your input here"
                className="w-full p-3 border border-purple-300 rounded-lg focus:ring-2 focus:ring-purple-500 shadow-sm"
              />
            </div>

            {/* Output Field */}
            <div className="flex-1">
              <label
                htmlFor="outputField"
                className="block text-sm font-medium text-purple-700 mb-2"
              >
                {toLabel}
              </label>
              <input
                id="outputField"
                type="text"
                value={outputValue}
                readOnly
                className="w-full p-3 border border-purple-300 rounded-lg bg-purple-50 shadow-sm"
              />
            </div>
          </div>

          {/* Convert Button */}
          <button
            onClick={handleConvert}
            className="w-40 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 shadow-md text-sm"
          >
            Convert
          </button>
        </div>
      </div>
    </>
  );
}

HexCraftDashboard.propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string,
    dropdowns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default HexCraftDashboard;
