import HexCraftDashboard from "../dashboard/HexCraftDashboard";
import { octalConverters } from "../staticData";
function OctalConversion() {
  return (
    <>
      <HexCraftDashboard config={{ id: "octal", dropdowns: octalConverters }} />
    </>
  );
}
export default OctalConversion;
