import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  // Define button styles
  const buttonStyles =
    "text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:outline-none focus:ring-purple-300 shadow-lg shadow-purple-500/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition-all duration-300";

  // Navigation items
  const navItems = [
    { path: "/json-validate", label: "JSON" },
    { path: "/base64-encode", label: "Encoder|Decoder" },
    { path: "/hexcraft-binary", label: "HexCraft" },
    { path: "/convert-to-pdf", label: "File Converter" },
    { path: "/text-checker", label: "Text" },
    { path: "/code-formatter", label: "Formatter" },
    { path: "/regex-tester", label: "Regex" },
    {path: "/random-password-generator", label: "Password Generator"},
    {path: "/from-csv-data-visualization", label: "Data Visualization"},
    {path: "/svg-editor", label: "Editor"}
  ];

  return (
    <header>
      <nav className="bg-purple-600 px-4 lg:px-6 py-3 border-b border-purple-200">
        <div className="flex items-center gap-4 mx-auto max-w-screen-xl">
          {navItems.map(({ path, label }) => (
            <Link key={path} to={path}>
              <button
                type="button"
                className={buttonStyles}
                aria-label={`Navigate to ${label}`}
              >
                {label}
              </button>
            </Link>
          ))}
        </div>
      </nav>
    </header>
  );
};

export default Header;
