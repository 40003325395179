import React, { useEffect, useState } from "react";
import ReactJson from "react18-json-view";
import JsonNavHeader from "./general/JsonNavHeader";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal"; // Import Modal

// Component for JSON input areas
const JsonInputArea = ({ label, value, onChange, jsonStatus }) => (
  <div>
    <h3 className="text-lg font-medium mb-2 text-black">{label}</h3>
    <JsonStatusArea label={label} status={jsonStatus} />
    <textarea
      className="w-full h-40 p-2 border border-purple-300 rounded-md"
      value={value}
      onChange={onChange}
      placeholder="Paste JSON here..."
    />
  </div>
);

// Component for JSON status area
const JsonStatusArea = ({ label, status }) => (
  <div>
    <p
      className={`text-center p-2 ${
        status === "valid"
          ? "text-green-500"
          : status === "invalid"
          ? "text-red-500"
          : "text-yellow-500"
      }`}
    >
      {status === "valid"
        ? "Valid JSON"
        : status === "invalid"
        ? "Invalid JSON"
        : ""}
    </p>
  </div>
);

// Button to trigger comparison
const CompareButton = ({ onClick }) => (
  <div className="mt-4 text-center">
    <button
      onClick={onClick}
      className="bg-purple-600 text-white px-6 py-2 rounded-md hover:bg-purple-700 shadow-md"
    >
      Compare
    </button>
  </div>
);

// Button to trigger summary modal
const SummaryButton = ({ onClick }) => (
  <div className="mt-4 text-center">
    <button
      onClick={onClick}
      className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 shadow-md"
    >
      Summary of Changes
    </button>
  </div>
);

// Component to show error messages
const JsonError = ({ error }) =>
  error && <p className="text-red-500 mt-4 text-center">{error}</p>;

// Updated JsonDiffDisplay Component to show modified keys in a table
const JsonDiffDisplay = ({ diff }) => {
  return (
    <div className="mt-4">
      <h3 className="text-lg font-medium mb-2 text-black">Differences</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse">
          <thead>
            <tr>
              <th className="border-b p-2 text-left">Key</th>
              <th className="border-b p-2 text-left">Old Value</th>
              <th className="border-b p-2 text-left">New Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(diff).map((key) => {
              const change = diff[key];
              return (
                change.type === "modified" && (
                  <tr key={key}>
                    <td className="border-b p-2">{key}</td>
                    <td className="border-b p-2">
                      <ReactJson
                        src={change.oldValue}
                        name={false}
                        collapsed={true}
                        theme="monokai"
                        enableClipboard={true}
                      />
                    </td>
                    <td className="border-b p-2">
                      <ReactJson
                        src={change.newValue}
                        name={false}
                        collapsed={true}
                        theme="monokai"
                        enableClipboard={true}
                      />
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Recursive function to calculate the diff
const deepCompare = (obj1, obj2, path = "") => {
  const changes = {};
  const addedKeys = [];
  const deletedKeys = [];
  const modifiedKeys = [];

  const traverse = (current1, current2, currentPath) => {
    // Compare both objects at the current path level
    Object.keys(current1).forEach((key) => {
      const fullPath = `${currentPath ? currentPath + "." : ""}${key}`;
      if (current2.hasOwnProperty(key)) {
        // If both values are objects, recurse deeper
        if (
          typeof current1[key] === "object" &&
          typeof current2[key] === "object"
        ) {
          const nestedDiff = traverse(current1[key], current2[key], fullPath);
          Object.assign(changes, nestedDiff);
        } else if (
          JSON.stringify(current1[key]) !== JSON.stringify(current2[key])
        ) {
          // Track modified keys
          changes[fullPath] = {
            type: "modified",
            oldValue: current1[key],
            newValue: current2[key],
          };
          modifiedKeys.push(fullPath);
        }
      } else {
        // Track deleted keys
        changes[fullPath] = { type: "deleted", value: current1[key] };
        deletedKeys.push(fullPath);
      }
    });

    // Check for added keys
    Object.keys(current2).forEach((key) => {
      const fullPath = `${currentPath ? currentPath + "." : ""}${key}`;
      if (!current1.hasOwnProperty(key)) {
        changes[fullPath] = { type: "added", value: current2[key] };
        addedKeys.push(fullPath);
      }
    });

    return changes;
  };

  const diff = traverse(obj1, obj2, path);
  return { diff, modifiedKeys, addedKeys, deletedKeys };
};

// JSON Diff Viewer Component
const JsonDiffViewer = () => {
  const [json1, setJson1] = useState("");
  const [json2, setJson2] = useState("");
  const [diff, setDiff] = useState(null);
  const [error, setError] = useState(null);
  const [json1Status, setJson1Status] = useState(null);
  const [json2Status, setJson2Status] = useState(null);
  const [stats, setStats] = useState({
    totalChanges: 0,
    modifiedKeys: [],
    addedKeys: [],
    deletedKeys: [],
  });
  const [isSummaryOpen, setIsSummaryOpen] = useState(false); // Modal state

  const validateJson = (json) => {
    try {
      if (json === "") return null;
      JSON.parse(json);
      return "valid";
    } catch (error) {
      return "invalid";
    }
  };

  useEffect(() => {
    setJson1Status(validateJson(json1));
  }, [json1]);

  useEffect(() => {
    setJson2Status(validateJson(json2));
  }, [json2]);

  const handleCompare = () => {
    try {
      const parsedJson1 = JSON.parse(json1);
      const parsedJson2 = JSON.parse(json2);

      const { diff, modifiedKeys, addedKeys, deletedKeys } = deepCompare(
        parsedJson1,
        parsedJson2
      );

      setDiff(diff);
      setStats({
        totalChanges:
          modifiedKeys.length + addedKeys.length + deletedKeys.length,
        modifiedKeys,
        addedKeys,
        deletedKeys,
      });
      setError(null);
    } catch (e) {
      setError("Invalid JSON format");
      setDiff(null);
    }
  };

  return (
    <>
      <JsonNavHeader />
      <ToastContainer />
      <div className="container mx-auto p-4 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <JsonInputArea
            label="JSON 1"
            value={json1}
            onChange={(e) => setJson1(e.target.value)}
            jsonStatus={json1Status}
          />
          <JsonInputArea
            label="JSON 2"
            value={json2}
            onChange={(e) => setJson2(e.target.value)}
            jsonStatus={json2Status}
          />
        </div>
        <CompareButton onClick={handleCompare} />
        <SummaryButton onClick={() => setIsSummaryOpen(true)} />
        <JsonError error={error} />
        {diff && <JsonDiffDisplay diff={diff} />}
      </div>

      <Modal
        isOpen={isSummaryOpen}
        onRequestClose={() => setIsSummaryOpen(false)}
        contentLabel="Summary of Changes"
        ariaHideApp={false}
        className="bg-white p-6 border border-gray-300 rounded-lg max-w-lg mx-auto shadow-lg"
      >
        <h2 className="text-xl font-bold mb-4 text-center">
          Summary of Changes
        </h2>
        <p className="text-gray-800">
          <strong>Total Changes:</strong> {stats.totalChanges}
        </p>
        {stats.modifiedKeys.length > 0 && (
          <div>
            <h4 className="font-medium text-purple-600 mt-2">Modified Keys:</h4>
            <ul className="list-disc list-inside">
              {stats.modifiedKeys.map((key) => (
                <li key={key}>{key}</li>
              ))}
            </ul>
          </div>
        )}
        {stats.addedKeys.length > 0 && (
          <div>
            <h4 className="font-medium text-green-600 mt-2">Added Keys:</h4>
            <ul className="list-disc list-inside">
              {stats.addedKeys.map((key) => (
                <li key={key}>{key}</li>
              ))}
            </ul>
          </div>
        )}
        {stats.deletedKeys.length > 0 && (
          <div>
            <h4 className="font-medium text-red-600 mt-2">Deleted Keys:</h4>
            <ul className="list-disc list-inside">
              {stats.deletedKeys.map((key) => (
                <li key={key}>{key}</li>
              ))}
            </ul>
          </div>
        )}
        <button
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
          onClick={() => setIsSummaryOpen(false)}
        >
          Close
        </button>
      </Modal>
    </>
  );
};

export default JsonDiffViewer;
