import React, { useState } from "react";
import Sentiment from "sentiment";
import InputSection from "./InputSection";
import AnalysisResult from "./AnalysisResult";
import DragDropFile from "./DragDropFile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextHeader from "../dashboard/TextHeader";

const SentimentDashboard = () => {
  const [text, setText] = useState("");
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAnalyze = () => {
    if (!text.trim()) {
      toast.error("Please enter text for analysis!");
      return;
    }

    setLoading(true);

    const sentiment = new Sentiment();
    try {
      const result = sentiment.analyze(text);
      setTimeout(() => {
        setAnalysis(result);
        setLoading(false);
        toast.success("Analysis completed successfully!");
      }, 500); // Simulated delay for better UX
    } catch (error) {
      console.error("Sentiment analysis failed:", error);
      toast.error("Error analyzing sentiment. Please try again.");
      setLoading(false);
    }
  };

  const resetAnalysis = () => {
    setText("");
    setAnalysis(null);
    toast.info("Analysis reset successfully.");
  };

  return (
    <>
      <TextHeader />
      <div className="sentiment-dashboard min-h-screen bg-white p-6">
        <ToastContainer position="top-right" autoClose={3000} />

        <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg p-6 flex flex-col md:flex-row gap-6">
          {/* Left Section: Input */}
          <div className="flex-1">
            <h1 className="text-3xl font-bold text-purple-700 mb-4 text-center">
              Sentiment Analysis
            </h1>
            <DragDropFile setText={setText} />
            <InputSection
              text={text}
              setText={setText}
              handleAnalyze={handleAnalyze}
              resetAnalysis={resetAnalysis}
              loading={loading}
            />
          </div>

          {/* Right Section: Graph */}
          {analysis && (
            <div className="flex-1">
              <AnalysisResult analysis={analysis} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SentimentDashboard;
