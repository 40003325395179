import base64Variable from "../base64Variable";

export const decodeText = (text, targetAlgo) => {
  const output_format = {
    result: null,
    success: false,
    error: null,
    warning: null,
  };

  if (targetAlgo === base64Variable.UTF_8) {
    const decodedBase64 = atob(text);
    const result = new TextDecoder("utf-8").decode(
      Uint8Array.from(decodedBase64, (char) => char.charCodeAt(0))
    );
    return { ...output_format, result: result, success: true };
  } else if (targetAlgo === base64Variable.ASCII) {
    const result = atob(text);
    return { ...output_format, result: result, success: true };
  } else if (targetAlgo === base64Variable.URL) {
    const result = decodeURIComponent(text);
    return { ...output_format, result: result, success: true };
  }
};
