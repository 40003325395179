import React, { useState, useRef } from "react";
import Papa from "papaparse";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { useDropzone } from "react-dropzone";
import DataVisualizationHeader from "./DataVisualizationHeader";
import { ToastContainer, toast } from "react-toastify";

// Register the required components for charts
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
);

const DataVisualizationDashboard = () => {
  const [data, setData] = useState(null); // Parsed CSV data
  const [graphType, setGraphType] = useState("bar"); // Default graph type
  const [chartData, setChartData] = useState({}); // Data formatted for Chart.js
  const [xAxis, setXAxis] = useState(""); // Selected column for X-axis
  const [yAxis, setYAxis] = useState(""); // Selected column for Y-axis
  const [chartTitle, setChartTitle] = useState("My Chart"); // Chart title
  const [selectedColor, setSelectedColor] = useState("rgba(75, 192, 192, 0.5)");

  const [filterValue, setFilterValue] = useState(""); // Value to filter data
  const [sortColumn, setSortColumn] = useState(""); // Column to sort data
  const [sortOrder, setSortOrder] = useState("asc"); // Sort order: asc or desc
  const [filename, setFileName] = useState(null);

  const chartRef = useRef(null); // Ref to access the chart

  // Generate a unique color for each dataset
  const generateColors = (count) => {
    return Array.from({ length: count }, () => {
      return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )}, ${Math.floor(Math.random() * 255)}, 0.7)`;
    });
  };

  // Handle file upload and CSV parsing
  const handleFileUpload = (file) => {
    setFileName(file.name);
    Papa.parse(file, {
      header: true, // Automatically handle headers
      skipEmptyLines: true, // Ignore empty lines
      complete: (result) => {
        if (result.errors.length) {
          toast.error("Error parsing CSV file.");
          return;
        }
        const parsedData = result.data;
        setData(parsedData);
        setXAxis(""); // Reset X-axis selection
        setYAxis(""); // Reset Y-axis selection
        setChartData({}); // Reset chart data
      },
      error: (error) => {
        toast.error("Failed to parse CSV file");
      },
    });
  };

  // Handle drop event (from react-dropzone)
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file.name.endsWith(".csv")) {
      toast.error("Please upload a valid CSV file.");
      return;
    }
    handleFileUpload(file);
  };

  // Handle drag and drop functionality
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  // Filter Data Function
  const filterData = (data) => {
    if (filterValue) {
      return data.filter((row) => {
        // Check if the value exists in any of the columns (you can refine this)
        return Object.values(row).some((val) =>
          val.toString().toLowerCase().includes(filterValue.toLowerCase())
        );
      });
    }
    return data;
  };

  // Sort Data Function
  const sortData = (data) => {
    if (sortColumn) {
      return data.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (sortOrder === "asc") {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    }
    return data;
  };

  // Apply filters and sorting to data
  const preprocessData = (data) => {
    let filteredData = filterData(data);
    return sortData(filteredData);
  };

  // Process chart data for visualization
  const processChartData = () => {
    if (!data || !xAxis || !yAxis) {
      toast.error("Please select both X-axis and Y-axis columns.");
      return;
    }

    const preprocessedData = preprocessData(data);

    const xLabels = preprocessedData.map((row) => row[xAxis]);
    const yValues = preprocessedData.map((row) => parseFloat(row[yAxis]) || 0);

    const colors = generateColors(xLabels.length);

    setChartData({
      labels: xLabels,
      datasets: [
        {
          label: `${yAxis} vs ${xAxis}`,
          data: yValues,
          backgroundColor: graphType === "pie" ? colors : selectedColor, // Use unique colors for pie chart
          borderColor:
            graphType === "pie"
              ? colors.map((c) => c.replace("0.7", "1"))
              : selectedColor.replace("0.5", "1"),
          borderWidth: 1,
        },
      ],
    });
  };

  // Export chart as image
  const exportChart = () => {
    const canvas = document.querySelector("canvas");
    if (canvas) {
      const link = document.createElement("a");
      link.download = "chart.png";
      link.href = canvas.toDataURL();
      link.click();
    }
  };

  // Handle graph type selection
  const handleGraphTypeChange = (event) => {
    setGraphType(event.target.value);
  };

  // Render selected graph type
  const renderGraph = () => {
    if (!chartData || !chartData.labels || !chartData.labels.length) {
      return <p className="mt-4 text-red-500"></p>;
    }

    // Dynamically set axis titles
    const options = {
      plugins: {
        title: {
          display: true,
          text: chartTitle,
        },
      },
      scales: {
        x: {
          type: "category",
          title: {
            display: true,
            text: xAxis || "X-Axis", // X-Axis title dynamically based on selected column
          },
        },
        y: {
          type: "linear",
          position: "left",
          title: {
            display: true,
            text: yAxis || "Y-Axis", // Y-Axis title dynamically based on selected column
          },
        },
      },
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      pan: {
        enabled: true,
        mode: "xy",
        speed: 10,
        threshold: 10,
      },
      zoom: {
        enabled: true,
        mode: "xy",
        speed: 0.1,
        sensitivity: 3,
        rangeMin: {
          x: 1,
          y: 0,
        },
        rangeMax: {
          x: 10,
          y: 10,
        },
      },
    };

    switch (graphType) {
      case "bar":
        return <Bar ref={chartRef} data={chartData} options={options} />;
      case "line":
        return <Line ref={chartRef} data={chartData} options={options} />;
      case "pie":
        return <Pie ref={chartRef} data={chartData} options={options} />;
      default:
        return <Bar ref={chartRef} data={chartData} options={options} />;
    }
  };

  return (
    <>
      <DataVisualizationHeader />
      <ToastContainer />

      <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-3xl font-bold text-center mb-4">
          CSV Data Visualization
        </h1>

        {/* Drag and Drop Area */}
        <div className="flex justify-center mt-2">
          <div
            {...getRootProps()}
            className="border-dashed border-4 p-4 text-center cursor-pointer rounded-md w-full sm:w-1/2"
          >
            <input {...getInputProps()} />
            <p className="text-xl text-gray-600">
              Drag & Drop CSV file here, or click to select a file
            </p>
            {filename && <h1>Uploaded File: {filename}</h1>}
          </div>
        </div>

        {/* Column Selection for Axes */}
        {data && (
          <div className="mt-2 flex justify-between gap-2">
            <div>
              <label className="block font-medium">X-Axis:</label>
              <select
                className="p-2 border rounded-md shadow-md text-sm bg-white"
                value={xAxis}
                onChange={(e) => setXAxis(e.target.value)}
              >
                <option value="">Select Column</option>
                {Object.keys(data[0]).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block font-medium">Y-Axis:</label>
              <select
                className="p-2 border rounded-md shadow-md text-sm bg-white"
                value={yAxis}
                onChange={(e) => setYAxis(e.target.value)}
              >
                <option value="">Select Column</option>
                {Object.keys(data[0]).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            {/* Graph Type Selector */}
            <div>
              <label className="block font-medium">Graph Type:</label>
              <select
                className="p-2 border rounded-md shadow-md text-sm bg-white"
                value={graphType}
                onChange={handleGraphTypeChange}
              >
                <option value="bar">Bar</option>
                <option value="line">Line</option>
                <option value="pie">Pie</option>
              </select>
            </div>

            {/* Chart Title */}
            <div>
              <label className="block font-medium">Chart Title:</label>
              <input
                type="text"
                className="p-2 border rounded-md shadow-md w-32 text-sm"
                value={chartTitle}
                onChange={(e) => setChartTitle(e.target.value)}
              />
            </div>

            {/* Dataset Color */}
            <div className="flex flex-col items-center">
              <label className="block font-medium">Color:</label>
              <input
                type="color"
                className="w-8 h-8 border-2 border-gray-300 rounded-full cursor-pointer"
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
              />
            </div>
          </div>
        )}

        {/* Filtering and Sorting Options */}
        {data && (
          <div className="mt-4 flex justify-between gap-2">
            <div>
              <label className="block font-medium">Filter:</label>
              <input
                type="text"
                className="p-2 border rounded-md shadow-md text-sm"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                placeholder="Filter by value"
              />
            </div>
            <div>
              <label className="block font-medium">Sort by:</label>
              <select
                className="p-2 border rounded-md shadow-md text-sm bg-white"
                value={sortColumn}
                onChange={(e) => setSortColumn(e.target.value)}
              >
                <option value="">Select Column</option>
                {Object.keys(data[0]).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>
            {sortColumn && (
              <div>
                <label className="block font-medium">Sort Order:</label>
                <select
                  className="p-2 border rounded-md shadow-md text-sm bg-white"
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                >
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
            )}
          </div>
        )}

        {/* Generate Chart Button */}
        {data && (
          <div className="mt-4 flex justify-center">
            <button
              className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              onClick={processChartData}
            >
              Generate Chart
            </button>
          </div>
        )}

        {/* Render the Chart */}
        <div className="mt-4 flex justify-center">{renderGraph()}</div>

        {/* Export Chart */}
        {chartData && chartData.labels && chartData.labels.length > 0 && (
          <div className="flex justify-center mt-4">
            <button
              className="px-6 py-3 bg-gray-500 text-white rounded-md hover:bg-gray-600"
              onClick={exportChart}
            >
              Export Chart as Image
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default DataVisualizationDashboard;
