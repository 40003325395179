import HexCraftDashboard from "../dashboard/HexCraftDashboard";
import { decimalConverters } from "../staticData";
function DecimalConversion() {
  return (
    <>
      <HexCraftDashboard
        config={{ id: "decimal", dropdowns: decimalConverters }}
      />
    </>
  );
}
export default DecimalConversion;
