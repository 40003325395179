import { configureStore } from "@reduxjs/toolkit";
import jsonValidateReducer from "./reducer/json/validator/jsonValidateReducer";
import base64Reducer from "./reducer/base64/base64Reducer";

const store = configureStore({
  reducer: {
    jsonValidateStore: jsonValidateReducer,
    base64Store: base64Reducer,
  },
});

export default store;
