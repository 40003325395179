import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";
import EditorHeader from "./EditorHeader";

// Ace Editor modes/themes
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-monokai";

// Toast Notifications
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import beautify from "js-beautify";

// Heroicons for UI buttons
import {
  ClipboardIcon,
  TrashIcon,
  ArrowDownTrayIcon,
  ArrowUpIcon,
  WrenchScrewdriverIcon,
  SunIcon,
  MoonIcon,
} from "@heroicons/react/24/outline";

// Reusable Button Component
const IconButton = ({ onClick, icon: Icon, label, className, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`${className} flex items-center space-x-1 p-2 rounded-lg transition hover:opacity-80 ${
      disabled ? "opacity-50 cursor-not-allowed" : ""
    }`}
    aria-label={label}
  >
    <Icon className="h-5 w-5" />
    <span></span>
  </button>
);

function HtmlCodeEditor() {
  const [htmlCode, setHtmlCode] = useState(
    "<!DOCTYPE html>\n<html>\n  <body>\n    <h1>Welcome to HTML Editor</h1>\n  </body>\n</html>"
  );

  const [fontSize, setFontSize] = useState(14);
  const [theme, setTheme] = useState("chrome");

  // Update HTML preview
  useEffect(() => {
    try {
      const iframe = document.getElementById("html-preview");
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(htmlCode);
      doc.close();
    } catch (error) {
      showError("Invalid HTML syntax. Preview failed.");
    }
  }, [htmlCode]);

  const handleCodeChange = (newCode) => setHtmlCode(newCode);
  const showError = (msg) => toast.error(msg);
  const showSuccess = (msg) => toast.success(msg);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file?.type === "text/html") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setHtmlCode(e.target.result);
        showSuccess("HTML file uploaded successfully!");
      };
      reader.readAsText(file);
    } else showError("Invalid file. Please upload an HTML file.");
  };

  const handleDownload = () => {
    const blob = new Blob([htmlCode], { type: "text/html" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "downloaded.html";
    link.click();
    showSuccess("Download started!");
  };

  const handleMinify = () => {
    try {
      const minified = htmlCode.replace(/\s+/g, " ").trim();
      setHtmlCode(minified);
      showSuccess("HTML minified!");
    } catch {
      showError("Failed to minify HTML!");
    }
  };

  const handleReformat = () => {
    try {
      // Use js-beautify to reformat the HTML code
      const formatted = beautify.html(htmlCode, {
        indent_size: 2,
        max_preserve_newlines: 1,
      });
      setHtmlCode(formatted);
      showSuccess("HTML formatted!");
    } catch (error) {
      showError("Failed to format HTML!");
    }
  };

  const handleClear = () => {
    setHtmlCode("");
    showSuccess("Editor cleared.");
  };

  const toggleTheme = () => setTheme(theme === "chrome" ? "monokai" : "chrome");

  return (
    <>
      <EditorHeader />

      <main className="h-screen flex flex-row bg-gray-50">
        {/* HTML Editor Section */}
        <section className="flex-1 flex flex-col border-r">
          <div className="p-4 flex items-center space-x-2">
            <IconButton
              onClick={handleClear}
              icon={TrashIcon}
              label="Clear"
              className="bg-red-500 text-white"
            />
            <label className="bg-blue-500 text-white flex items-center space-x-1 p-2 rounded-lg cursor-pointer hover:opacity-80">
              <ArrowUpIcon className="h-5 w-5" />
              <span></span>
              <input
                type="file"
                accept=".html"
                onChange={handleUpload}
                className="hidden"
              />
            </label>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(htmlCode);
                showSuccess("Copied to clipboard!");
              }}
              icon={ClipboardIcon}
              label="Copy"
              className="bg-green-500 text-white"
            />
            <IconButton
              onClick={toggleTheme}
              icon={theme === "chrome" ? MoonIcon : SunIcon}
              label={theme === "chrome" ? "Dark" : "Light"}
              className="bg-gray-800 text-white"
            />
            <input
              type="range"
              min="12"
              max="20"
              value={fontSize}
              onChange={(e) => setFontSize(+e.target.value)}
              className="ml-2"
              aria-label="Font size slider"
            />
          </div>

          <AceEditor
            mode="html"
            theme={theme}
            value={htmlCode}
            onChange={handleCodeChange}
            name="html-editor"
            editorProps={{ $blockScrolling: true }}
            setOptions={{ showLineNumbers: true, tabSize: 2, useWorker: false }}
            style={{ width: "100%", height: "100%", fontSize }}
          />
        </section>

        {/* HTML Preview Section */}
        <section className="flex-1 flex flex-col border-r">
          <div className="p-4 flex items-center space-x-2">
            <IconButton
              onClick={handleDownload}
              icon={ArrowDownTrayIcon}
              label="Download"
              className="bg-blue-800 text-white"
            />
          </div>
          <div
            className="flex-1 border bg-white rounded-lg flex items-center justify-center"
            aria-label="HTML Preview"
          >
            <iframe
              id="html-preview"
              title="HTML Preview"
              style={{ width: "100%", height: "100%" }}
              sandbox="allow-scripts allow-same-origin"
            />
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
}

export default HtmlCodeEditor;
