const base64Variable = {
  ENCODING: "encoding",
  ENCODE: "encode",
  DECODING: "decoding",
  DECODE: "decode",
  UTF_8: "UTF-8",
  ASCII: "ASCII",
  URL: "URL"
};
export default base64Variable;
