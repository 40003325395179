import React from "react";
import { Link, useLocation } from "react-router-dom";

const CodeFormatterHeader = () => {
  const location = useLocation();

  // Function to determine button classes based on active state
  const buttonClasses = (isActive) =>
    `px-4 py-2 text-sm font-medium text-center rounded-lg transition-all duration-300 ${
      isActive
        ? "text-white bg-purple-700 shadow-md"
        : "text-purple-700 bg-white hover:bg-purple-100"
    } focus:ring-4 focus:outline-none focus:ring-purple-300`;

  // Navigation items
  const navItems = [
    { path: "/code-formatter", name: "Formatter" },
  ];

  return (
    <header>
      <nav className="bg-white border-b border-purple-200 px-6 py-3">
        <div className="flex justify-center items-center mx-auto max-w-screen-xl">
          <ul className="flex space-x-6">
            {navItems.map((item) => (
              <li key={item.path}>
                <Link to={item.path}>
                  <button
                    type="button"
                    className={buttonClasses(location.pathname === item.path)}
                  >
                    {item.name}
                  </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default CodeFormatterHeader;
