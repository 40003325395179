import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_right_text_json_validate_action } from "../../../action/json/validator/jsonValidateAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function JsonTreeViewButton() {
  const left_screen = useSelector(
    (state) => state.jsonValidateStore?.left_screen || { text: "" }
  );

  const dispatch = useDispatch();

  const handleJsonTreeView = async () => {
    if (!left_screen.text.trim()) {
      toast.error("Input is empty! Please provide JSON text.", {
        autoClose: 3000,
      });
      return;
    }

    try {
      const leftJson = JSON.parse(left_screen.text);
      dispatch(
        set_right_text_json_validate_action({
          text: JSON.stringify(leftJson, null, 2), // Pretty print JSON
          isValid: true,
        })
      );
      toast.success("JSON is valid and displayed as a tree view!", {
        autoClose: 3000,
      });
    } catch (error) {
      dispatch(
        set_right_text_json_validate_action({
          text: `Invalid JSON: ${error.message}`,
          isValid: false,
        })
      );
      toast.error(`Invalid JSON: ${error.message}`, { autoClose: 3000 });
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-full space-y-4">
      <button
        type="button"
        onClick={handleJsonTreeView}
        className="bg-purple-600 text-white hover:bg-purple-700 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 shadow-md transition-all"
      >
        JSON Tree View
      </button>
      <ToastContainer />
    </div>
  );
}

export default JsonTreeViewButton;
