import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { set_output_text_base64_action } from "../../../action/base64/base64Action";
import { ToastContainer } from "react-toastify";
import base64Variable from "../base64Variable";
import {
  showToastError,
  showToastSuccess,
  showToastWarning,
} from "../../toast/ToastService";
import { encodeText } from "../encode/EncodingService";
import { decodeText } from "../decode/DecodingService";
import { dropDownStyle } from "../../../style/commonStyle";

const Base64ActionBoard = ({
  config: {
    width = 800,
    height = 160,
    button_name = "Submit",
    ACTION_TYPE = base64Variable.ENCODE,
  } = {},
}) => {
  const [targetAlgo, setTargetAlgo] = useState(base64Variable.UTF_8);
  const inputText = useSelector((state) => state.base64Store.input.text);
  const dispatch = useDispatch();

  const selectOptions = [
    { value: base64Variable.UTF_8, label: "UTF-8" },
    { value: base64Variable.ASCII, label: "ASCII" },
    { value: base64Variable.URL, label: "URL" },
  ];

  const handleAction = () => {
    if (!inputText) {
      showToastError("Please write or paste input text.");
      return;
    }

    const actionFunction =
      ACTION_TYPE === base64Variable.ENCODE ? encodeText : decodeText;

    try {
      const result_info = actionFunction(inputText, targetAlgo);

      if (result_info.success) {
        dispatch(set_output_text_base64_action({ text: result_info.result }));
        showToastSuccess("Action performed successfully!");
      } else if (result_info.error) {
        showToastError(`Error: ${result_info.error}`);
      } else if (result_info.warning) {
        if (result_info.result) {
          dispatch(set_output_text_base64_action({ text: result_info.result }));
        }
        showToastWarning(`Warning: ${result_info.warning}`);
      }
    } catch (error) {
      showToastError(
        `${
          ACTION_TYPE === base64Variable.ENCODE ? "Encoding" : "Decoding"
        } failed.`
      );
    }
  };

  return (
    <div
      className="border rounded-lg shadow-lg p-6 bg-white dark:bg-purple-400 my-10"
      style={{ width, height }}
    >
      <ToastContainer />
      <div className="mb-6">
        <label
          htmlFor="encoding"
          className="block text-sm font-medium text-purple-700 mb-2"
        >
          Select Target
        </label>
        <select
          id="targetAlgo"
          value={targetAlgo}
          onChange={(e) => setTargetAlgo(e.target.value)}
          className={`${dropDownStyle}`}
        >
          {selectOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <button
        onClick={handleAction}
        className="w-full py-2 px-4 bg-purple-600 text-white font-medium rounded-lg hover:bg-purple-700 transition-all duration-300"
      >
        {button_name}
      </button>
    </div>
  );
};

export default Base64ActionBoard;
