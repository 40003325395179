import React from "react";
import PropTypes from "prop-types";

const JsonPiller = ({
  width = "w-2",
  height = "h-full",
  bgColor = "",
}) => {
  return (
    <div
      className={`${width} ${bgColor} ${height} rounded`}
      role="presentation"
      aria-hidden="true"
    />
  );
};

JsonPiller.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string,
};

export default React.memo(JsonPiller);
