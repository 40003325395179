import React from "react";
import { Link, useLocation } from "react-router-dom";

const Base64Header = () => {
  const location = useLocation();

  // Define button styles for active and inactive states
  const buttonStyles = {
    active:
      "text-white bg-purple-600 shadow-md focus:ring-4 focus:ring-purple-300",
    inactive:
      "text-purple-700 bg-white hover:bg-purple-100 focus:ring-4 focus:ring-purple-300",
  };

  // Navigation items
  const navItems = [
    { path: "/base64-encode", name: "Encode" },
    { path: "/base64-decode", name: "Decode" },
  ];

  return (
    <header>
      <nav className="bg-white border-b border-purple-300 px-6 py-3">
        <div className="flex justify-center items-center mx-auto max-w-screen-xl">
          <ul className="flex space-x-6">
            {navItems.map((item) => (
              <li key={item.path}>
                <Link to={item.path}>
                  <button
                    type="button"
                    className={`px-4 py-2 text-sm font-medium text-center rounded-lg transition-all duration-300 ${
                      location.pathname === item.path
                        ? buttonStyles.active
                        : buttonStyles.inactive
                    }`}
                    aria-label={`Navigate to ${item.name}`}
                  >
                    {item.name}
                  </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Base64Header;
