import React, { useState } from "react";
import ImageHeader from "./dashboard/ImageHeader";
import ImageUpload from "./dashboard/ImageUpload";

const ImageConversion = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (file) => {
    setSelectedFile(file); // Update the selected file state
  };

  return (
    <>
      <ImageHeader />
      <div>
        <ImageUpload
          selectedFile={selectedFile}
          onFileChange={handleFileChange}
        />
      </div>
    </>
  );
};

export default ImageConversion;
