import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_right_text_json_validate_action } from "../../../action/json/validator/jsonValidateAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function JsonValidateButton() {
  const left_screen = useSelector(
    (state) => state.jsonValidateStore?.left_screen || { text: "" }
  );

  const dispatch = useDispatch();

  const handleJsonValidation = () => {
    try {
      JSON.parse(left_screen.text);
      dispatch(
        set_right_text_json_validate_action({
          text: "JSON is valid!",
          isValid: true,
        })
      );
      toast.success("JSON is valid!", { autoClose: 3000 });
    } catch (error) {
      dispatch(
        set_right_text_json_validate_action({
          text: `Invalid JSON: ${error.message}`,
          isValid: false,
        })
      );
      toast.error(`Invalid JSON: ${error.message}`, { autoClose: 3000 });
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen space-y-4">
      <button
        type="button"
        onClick={handleJsonValidation}
        className="bg-purple-600 text-white hover:bg-purple-700 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 shadow-md transition-all"
      >
        Validate JSON
      </button>
      <ToastContainer />
    </div>
  );
}

export default JsonValidateButton;
