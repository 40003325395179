import React from "react";
import {
  ClipboardDocumentIcon,
  CloudArrowUpIcon,
  ArrowDownIcon,
} from "@heroicons/react/20/solid";

function Base64EncodingToolBar({
  onCopy,
  onTextFileUpload,
  onDownload, // New prop for handling download action
  width = 400,
  height = 50,
  active_copy_button = true,
  active_download_button = true,
  active_upload_text_file_button = true,
  active_image_upload_file_button = true,
}) {
  return (
    <div
      className="flex gap-2 rounded-md shadow-lg -mb-0"
      style={{ width: width, height: height }}
    >
      {/* Copy Button */}
      {active_copy_button && (
        <button
          onClick={onCopy} // Trigger the copy action
          className="flex items-center justify-center bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200 h-full px-4"
        >
          <ClipboardDocumentIcon className="h-5 w-5 mr-2" title="Copy" />
        </button>
      )}

      {/* Download Button */}
      <button
        onClick={onDownload} // Trigger the download action
        className="flex items-center justify-center bg-yellow-600 text-white rounded-md shadow-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-all duration-200 h-full px-4"
      >
        <ArrowDownIcon className="h-5 w-5 mr-2" title="Download" />
      </button>

      {/* Upload Text File */}
      {active_upload_text_file_button && (
        <label
          htmlFor="text-file-upload"
          className="flex items-center justify-center bg-green-600 text-white rounded-md shadow-md cursor-pointer hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200 h-full px-4"
        >
          <CloudArrowUpIcon className="h-5 w-5 mr-2" title="Upload Text" />
          <input
            id="text-file-upload"
            type="file"
            accept=".txt"
            onChange={onTextFileUpload}
            className="hidden"
          />
        </label>
      )}
    </div>
  );
}

export default Base64EncodingToolBar;
