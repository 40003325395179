import React, { useState } from "react";
import Header from "./Dashboard/Header";
import { jsPDF } from "jspdf";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDropzone } from "react-dropzone";

const ConvertToPdf = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  // PDF configuration states
  const [orientation, setOrientation] = useState("portrait");
  const [pageSize, setPageSize] = useState("a4");
  const [margin, setMargin] = useState(10);
  const [imageWidth, setImageWidth] = useState(210);
  const [imageHeight, setImageHeight] = useState(297);

  const validateFile = (selectedFile) => {
    const validTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
      "image/bmp",
    ];

    if (selectedFile && validTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
    } else {
      setFile(null);
      toast.error("Please upload a valid image (PNG, JPEG, JPG, GIF, BMP).");
    }
  };

  const onDrop = (acceptedFiles) => {
    validateFile(acceptedFiles[0]);
  };

  const convertToPdf = () => {
    if (!file) return;

    setLoading(true);

    const doc = new jsPDF(orientation, "mm", pageSize);

    const reader = new FileReader();
    reader.onloadend = () => {
      const imgData = reader.result;

      const ext = file.name.split(".").pop().toLowerCase();
      let imageType = "PNG";
      if (ext === "jpg" || ext === "jpeg") imageType = "JPEG";
      if (ext === "gif") imageType = "GIF";
      if (ext === "bmp") imageType = "BMP";

      doc.addImage(
        imgData,
        imageType,
        margin,
        margin,
        imageWidth - margin * 2,
        imageHeight - margin * 2
      );

      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);

      setLoading(false);
      toast.success("PDF created successfully!");
    };

    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/jpeg, image/jpg, image/gif, image/bmp",
    onDrop,
  });

  return (
    <>
      <Header />
      <div className="min-h-screen bg-white flex flex-col items-center justify-center py-10">
        <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg space-y-6">
          <h1 className="text-3xl font-semibold text-center text-purple-700">
            Convert Image to PDF
          </h1>
          <p className="text-center text-purple-700">
            Upload or drag-and-drop your image (PNG, JPEG, JPG, GIF, BMP) to
            convert it to a PDF.
          </p>

          {/* Drag and Drop area */}
          <div
            {...getRootProps()}
            className="w-full p-6 border-2 border-dashed border-purple-300 rounded-lg flex justify-center items-center cursor-pointer transition duration-300 hover:border-purple-600 hover:bg-purple-50"
          >
            <input {...getInputProps()} />
            <p className="text-center">
              Drag & Drop your image here, or click to select it.
            </p>
          </div>

          {/* PDF Customization Options */}
          <div className="mt-6 space-y-4">
            <div>
              <label className="block ">Page Orientation</label>
              <select
                value={orientation}
                onChange={(e) => setOrientation(e.target.value)}
                className="w-full p-2 mt-1 border border-purple-300 rounded"
              >
                <option value="portrait">Portrait</option>
                <option value="landscape">Landscape</option>
              </select>
            </div>

            <div>
              <label className="block ">Page Size</label>
              <select
                value={pageSize}
                onChange={(e) => setPageSize(e.target.value)}
                className="w-full p-2 mt-1 border border-purple-300 rounded"
              >
                <option value="a4">A4</option>
                <option value="letter">Letter</option>
              </select>
            </div>

            <div>
              <label className="block ">Page Margin (mm)</label>
              <input
                type="number"
                value={margin}
                onChange={(e) => setMargin(Number(e.target.value))}
                className="w-full p-2 mt-1 border border-purple-300 rounded"
                min={0}
              />
            </div>

            <div>
              <label className="block">Image Width (mm)</label>
              <input
                type="number"
                value={imageWidth}
                onChange={(e) => setImageWidth(Number(e.target.value))}
                className="w-full p-2 mt-1 border border-purple-300 rounded"
                min={10}
              />
            </div>

            <div>
              <label className="block ">Image Height (mm)</label>
              <input
                type="number"
                value={imageHeight}
                onChange={(e) => setImageHeight(Number(e.target.value))}
                className="w-full p-2 mt-1 border border-purple-300 rounded"
                min={10}
              />
            </div>
          </div>

          {/* File info and upload button */}
          <div className="flex flex-col items-center mt-6">
            {file && <p className="text-purple-700 font-medium">{file.name}</p>}

            <button
              onClick={convertToPdf}
              className="w-full py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 mt-4"
              disabled={loading || !file}
            >
              {loading ? "Converting..." : "Convert to PDF"}
            </button>
          </div>

          {/* Display download link after successful PDF conversion */}
          {pdfUrl && (
            <a
              href={pdfUrl}
              download="converted.pdf"
              className="block text-center text-purple-600 mt-4 hover:underline"
            >
              Download PDF
            </a>
          )}
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ConvertToPdf;
