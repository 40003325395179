import React from "react";

const InputSection = ({ text, setText, handleAnalyze, resetAnalysis, loading }) => {
  return (
    <div>
      {/* Text Input */}
      <textarea
        className="w-full border border-purple-300 rounded-lg p-3 focus:ring-2 focus:ring-purple-400 outline-none resize-none bg-white"
        placeholder="Enter text here..."
        rows="6"
        value={text}
        onChange={(e) => setText(e.target.value)}
      ></textarea>

      {/* Buttons */}
      <div className="flex justify-end gap-4 mt-4">
        <button
          onClick={resetAnalysis}
          className="bg-purple-200 text-purple-700 px-4 py-2 rounded-lg hover:bg-purple-300 transition duration-200"
        >
          Reset
        </button>
        <button
          onClick={handleAnalyze}
          className={`px-4 py-2 rounded-lg ${
            loading
              ? "bg-purple-400 text-white cursor-not-allowed"
              : "bg-purple-600 text-white hover:bg-purple-700 transition duration-200"
          }`}
          disabled={loading}
        >
          {loading ? "Analyzing..." : "Analyze"}
        </button>
      </div>
    </div>
  );
};

export default InputSection;
