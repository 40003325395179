import HexCraftDashboard from "../dashboard/HexCraftDashboard";
import { asciiTextConverters } from "../staticData";
function AsciiTextConversion() {
  return (
    <>
      <HexCraftDashboard
        config={{ id: "ascii", dropdowns: asciiTextConverters }}
      />
    </>
  );
}
export default AsciiTextConversion;
