import React, { useState } from "react";
import { ClipboardDocumentIcon } from "@heroicons/react/20/solid";
import AceEditor from "react-ace";
import { js_beautify, html_beautify, css_beautify } from "js-beautify";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-chrome";
import CodeFormatterHeader from "./CodeFormatterHeader";
import { dropDownStyle } from "../../style/commonStyle";

const formatters = {
  javascript: (code, options) => js_beautify(code, options),
  html: (code, options) => html_beautify(code, options),
  css: (code, options) => css_beautify(code, options),
};

const CodeFormatterDashboard = () => {
  const [inputCode, setInputCode] = useState("");
  const [outputCode, setOutputCode] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [indentation, setIndentation] = useState(2);

  const handleFormatCode = () => {
    const format = formatters[language];
    if (format) {
      try {
        const formattedCode = format(inputCode, { indent_size: indentation });
        setOutputCode(formattedCode);
      } catch (error) {
        alert(`Formatting failed: ${error.message}`);
        setOutputCode(inputCode); // Fallback to original code
      }
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  return (
    <>
      <CodeFormatterHeader />
      <div className="min-h-screen bg-white flex flex-col items-center justify-center p-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-7xl">
          {/* Input Section */}
          <div
            className="flex flex-col bg-white rounded-lg shadow-lg p-8 relative -mx-64"
            style={{ height: "900px", width: "650px" }}
          >
            <button
              onClick={() => copyToClipboard(inputCode)}
              className="absolute top-2 text-purple-600 hover:text-purple-800"
              title="Copy Input"
            >
              <ClipboardDocumentIcon className="h-6 w-6" />
            </button>
            <AceEditor
              mode={language === "javascript" ? "javascript" : language}
              theme="chrome"
              value={inputCode}
              onChange={setInputCode}
              fontSize={16}
              width="100%"
              height="100%"
              setOptions={{
                useWorker: false,
                showLineNumbers: true,
                tabSize: indentation,
              }}
              className="border border-purple-300 rounded-md"
            />
          </div>

          {/* Control Panel */}
          <div
            className="bg-white rounded-lg shadow-lg p-8 flex flex-col justify-between self-center"
            style={{ height: "auto", minHeight: "300px" }}
          >
            <div>
              <label className="block text-purple-700 font-medium mb-2">
                Language
              </label>
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className={`${dropDownStyle}`}
              >
                <option value="javascript">JavaScript</option>
                <option value="html">HTML</option>
                <option value="css">CSS</option>
              </select>
            </div>
            <div>
              <label className="block text-purple-700 font-medium mb-2">
                Indentation
              </label>
              <select
                value={indentation}
                onChange={(e) => setIndentation(Number(e.target.value))}
                className={`${dropDownStyle}`}
              >
                <option value={2}>2 Spaces</option>
                <option value={4}>4 Spaces</option>
                <option value={8}>8 Spaces</option>
              </select>
            </div>
            <button
              onClick={handleFormatCode}
              className="px-6 py-3 bg-purple-600 text-white font-bold rounded-lg hover:bg-purple-700 transition"
            >
              Format Code
            </button>
          </div>

          {/* Output Section */}
          <div
            className="flex flex-col bg-white rounded-lg shadow-lg p-8 relative"
            style={{ height: "900px", width: "650px" }}
          >
            <button
              onClick={() => copyToClipboard(outputCode)}
              className="absolute top-2 text-purple-600 hover:text-purple-800"
              title="Copy Output"
            >
              <ClipboardDocumentIcon className="h-6 w-6" />
            </button>

            <AceEditor
              mode={language === "javascript" ? "javascript" : language}
              theme="chrome"
              value={outputCode}
              readOnly
              fontSize={16}
              width="100%"
              height="100%"
              setOptions={{
                useWorker: false,
                showLineNumbers: true,
                tabSize: indentation,
              }}
              className="border border-purple-300 rounded-md"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CodeFormatterDashboard;
