import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-github";
import RegexTesterHeader from "./RegexTesterHeader";

const RegexTesterDashBoard = () => {
  const [pattern, setPattern] = useState(null);
  const [flags, setFlags] = useState({
    g: true,
    i: false,
    m: false,
    s: false,
    u: false,
    y: false,
  });
  const [isValidRegex, setIsValidRegex] = useState(false);
  const [testString, setTestString] = useState("");
  const [highlightedString, setHighlightedString] = useState("");

  const computeFlagString = () => {
    return Object.keys(flags)
      .filter((key) => flags[key])
      .join("");
  };

  // Validate regex pattern and flags
  useEffect(() => {
    const flagString = computeFlagString();
    try {
      new RegExp(pattern, flagString);
      setIsValidRegex(true);
    } catch {
      setIsValidRegex(false);
    }
  }, [pattern, flags]);

  // Handle text highlighting
  useEffect(() => {
    const flagString = computeFlagString();
    if (isValidRegex && pattern) {
      try {
        const regex = new RegExp(pattern, flagString);
        const matches = Array.from(testString.matchAll(regex));

        let highlightedText = testString;
        let offset = 0;

        for (const match of matches) {
          const matchText = match[0];
          const startIndex = match.index + offset;
          const endIndex = startIndex + matchText.length;

          highlightedText =
            highlightedText.slice(0, startIndex) +
            `<span style='background-color: #D8BFD8; color: #4B0082; border-radius: 3px; padding: 0 2px;'>${matchText}</span>` +
            highlightedText.slice(endIndex);

          offset +=
            `<span style='background-color: #D8BFD8; color: #4B0082; border-radius: 3px; padding: 0 2px;'></span>`
              .length;
        }

        setHighlightedString(highlightedText);
      } catch {
        setHighlightedString(testString);
      }
    } else {
      setHighlightedString(testString);
    }
  }, [testString, pattern, flags, isValidRegex]);

  const handleFlagChange = (flag) => {
    setFlags((prevFlags) => ({ ...prevFlags, [flag]: !prevFlags[flag] }));
  };

  return (
    <>
      <RegexTesterHeader />

      <div className="container mx-auto mt-10 p-8 bg-white rounded-lg shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Regex Pattern Input */}
          <div>
            <label
              htmlFor="pattern"
              className="block text-lg font-medium text-purple-700 mb-2"
            >
              Regex Pattern:
            </label>
            <input
              id="pattern"
              type="text"
              value={pattern}
              onChange={(e) => setPattern(e.target.value)}
              placeholder="e.g., \\d+"
              className="w-full p-4 border rounded-md text-lg focus:outline-none  border-purple-400"
            />
          </div>

          {/* Flags Dropdown */}
          <div>
            <label
              htmlFor="flags"
              className="block text-lg font-medium text-purple-700 mb-2"
            >
              Flags:
            </label>
            <div className="relative inline-block text-left">
              <div className="flex items-center space-x-2">
                {Object.keys(flags).map((flag) => (
                  <div
                    key={flag}
                    className="flex items-center space-x-1 cursor-pointer"
                    onClick={() => handleFlagChange(flag)}
                  >
                    <input
                      type="checkbox"
                      checked={flags[flag]}
                      readOnly
                      className="form-checkbox text-purple-600"
                    />
                    <span className="text-purple-700 capitalize">{flag}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Test String Input with Ace Editor */}
        <div className="mt-8">
          <label
            htmlFor="testString"
            className="block text-lg font-medium text-purple-700 mb-2"
          >
            Test String:
          </label>
          <AceEditor
            mode="text"
            theme="github"
            name="testString"
            onChange={(value) => setTestString(value)}
            value={testString}
            fontSize={16}
            showPrintMargin={false}
            highlightActiveLine={true}
            width="100%"
            height="200px"
            setOptions={{
              showLineNumbers: true,
              tabSize: 2,
            }}
            className="border rounded-lg shadow-sm"
          />
        </div>

        {/* Highlighted Output */}
        <div
          className="mt-8 p-4 border rounded-lg bg-white shadow-sm text-lg "
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
          dangerouslySetInnerHTML={{ __html: highlightedString }}
        ></div>
      </div>
    </>
  );
};

export default RegexTesterDashBoard;
