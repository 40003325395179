import React, { useCallback } from "react";

function Base64Form({
  value = "",
  defaultHeight = 400,
  defaultWidth = 1200,
  minHeight = 400,
  maxHeight = 600,
  placeholder = "",
  className = "-my-3",
  readOnly = false,
  style = {},
  onChange,
}) {
  const handleInputChange = useCallback(
    (e) => {
      const newContent = e.target.value;
      if (onChange) {
        onChange(newContent); // Pass content to the parent
      }
    },
    [onChange]
  );

  return (
    <div className={`p-4 ${className}`}>
      <textarea
        value={value} // Controlled by the parent component
        onChange={handleInputChange}
        style={{
          width: `${defaultWidth}px`,
          height: `${defaultHeight}px`,
          resize: "vertical",
          minHeight: `${minHeight}px`,
          maxHeight: `${maxHeight}px`,
          ...style,
        }}
        className="border border-purple-300 rounded-md p-2 text-purple-700 bg-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
        placeholder={placeholder}
        readOnly={readOnly}
        aria-label={placeholder || "Base64 input"}
      />
    </div>
  );
}

export default Base64Form;
