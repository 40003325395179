import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/dashboard/Header";
import JsonValidate from "./components/json/validator/JsonValidate";
import JsonFormot from "./components/json/formattor/JsonFormot";
import JsonTreeView from "./components/json/tree/JsonTreeView";
import Base64Dashboard from "./components/base64/dashboard/Base64Dashboard";
import base64Variable from "./components/base64/base64Variable";
import DecimalConversion from "./components/hexcraft/decimal/DecimalConversion";
import OctalConversion from "./components/hexcraft/octal/OctalConversion";
import HexaDecimalConversion from "./components/hexcraft/hexadecimal/HexaDecimalConversion";
import AsciiTextConversion from "./components/hexcraft/ascii_text/AsciiTextConversion";
import BinaryConversion from "./components/hexcraft/binary/BinaryConversion";
import ConvertToPdf from "./components/file_converter/ConvertToPdf";
import ImageConversion from "./components/images/ImageConversion";
import TextDiffChecker from "./components/text/TextDiffChecker";
import SentiMentDashbord from "./components/text/sentiment/SentiMentDashbord";
import CodeFormatterDashboard from "./components/code_formatter/CodeFormatterDashboard";
import RegexTesterDashBoard from "./components/regex_tester/RegexTesterDashboard";
import RandomPasswordGenerator from "./components/random_password_generator/RandomPasswordGenerator";
import JsonDiffViewer from "./components/json/JsonDiffViewer";
import ConvertToJson from "./components/file_converter/ConvertToJson";
import DataVisualizationDashboard from "./components/data_visualization/DataVisualizationDashboard";
import SvgCodeEditor from "./components/editor/SvgCodeEditor";
import HtmlCodeEditor from "./components/editor/HtmlCodeEditor";
import JsCodeEditor from "./components/editor/JsCodeEditor";
import JsonPathFinder from "./components/json/JsonPathFinder";
import TagManager from "react-gtm-module";
const tagManagersArgs = {
  gtmId: 'GTM-MKCG6QJN',
}
TagManager.initialize(tagManagersArgs)
const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/json-validate" element={<JsonValidate />} />
          <Route path="/json-format" element={<JsonFormot />} />
          <Route path="/json-tree-view" element={<JsonTreeView />} />
          <Route
            path="/base64-encode"
            element={
              <Base64Dashboard
                button_name="Encode"
                ACTION_TYPE={base64Variable.ENCODE}
              />
            }
          />
          <Route
            path="/base64-decode"
            element={
              <Base64Dashboard
                button_name="Decode"
                ACTION_TYPE={base64Variable.DECODE}
              />
            }
          />
          <Route
            path="/hexcraft-hexadecimal"
            element={<HexaDecimalConversion />}
          />
          <Route path="/hexcraft-binary" element={<BinaryConversion />} />
          <Route path="/hexcraft-octal" element={<OctalConversion />} />
          <Route path="/hexcraft-decimal" element={<DecimalConversion />} />
          <Route
            path="/hexcraft-ascii-text"
            element={<AsciiTextConversion />}
          />
          <Route path="/convert-to-pdf" element={<ConvertToPdf />} />
          <Route path="/image-conversion" element={<ImageConversion />} />
          <Route path="/text-checker" element={<TextDiffChecker />} />
          <Route path="/text-sentiment" element={<SentiMentDashbord />} />
          <Route path="/code-formatter" element={<CodeFormatterDashboard />} />
          <Route path="/regex-tester" element={<RegexTesterDashBoard />} />
          <Route
            path="/random-password-generator"
            element={<RandomPasswordGenerator />}
          />
          <Route path="/json-diff-viewer" element={<JsonDiffViewer />} />
          <Route path="/convert-to-json" element={<ConvertToJson />} />
          <Route
            path="/from-csv-data-visualization"
            element={<DataVisualizationDashboard />}
          />
          <Route path="/svg-editor" element={<SvgCodeEditor />} />
          <Route path="/html-editor" element={<HtmlCodeEditor />} />
          <Route path="/js-editor" element={<JsCodeEditor />} />
          <Route path="/json-pathfinder" element={<JsonPathFinder />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
