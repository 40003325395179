import React, { useState, useEffect } from "react";
import RandomPasswordGeneratorHeader from "./RandomPasswordGeneratorHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import clsx from "clsx"; // For conditional classNames

function RandomPasswordGenerator() {
  const [passwordLength, setPasswordLength] = useState(50);
  const [useUppercase, setUseUppercase] = useState(true);
  const [useLowercase, setUseLowercase] = useState(true);
  const [useNumbers, setUseNumbers] = useState(true);
  const [useSymbols, setUseSymbols] = useState(false);
  const [password, setPassword] = useState("");
  const [strength, setStrength] = useState("Weak");

  const generatePassword = () => {
    const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lower = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*()_+[]{}|;:',.<>?";
    let charPool = "";

    if (useUppercase) charPool += upper;
    if (useLowercase) charPool += lower;
    if (useNumbers) charPool += numbers;
    if (useSymbols) charPool += symbols;

    if (!charPool) return ""; // Prevent empty character pool

    let generatedPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charPool.length);
      generatedPassword += charPool[randomIndex];
    }
    return generatedPassword;
  };

  // Updated strength calculation function
  const calculateStrength = () => {
    let score = 0;
    if (passwordLength >= 8) score++;
    if (useUppercase) score++;
    if (useLowercase) score++;
    if (useNumbers) score++;
    if (useSymbols) score++;
    if (passwordLength >= 12) score++;

    // Adjust strength levels based on the score
    if (score <= 2) return "Very Weak";
    if (score === 3) return "Weak";
    if (score === 4) return "Good";
    if (score === 5) return "Strong";
    return "Very Strong";
  };

  const strengthColor = (strength) => {
    switch (strength) {
      case "Very Weak":
        return "text-red-600";
      case "Weak":
        return "text-blue-600";
      case "Good":
        return "text-yellow-600";
      case "Strong":
        return "text-green-900";
      case "Very Strong":
        return "text-green-700";
      default:
        return "text-gray-700";
    }
  };

  const handleCopyPassword = () => {
    if (password.length !== 0) {
      navigator.clipboard.writeText(password);
      toast.success("Password copied to clipboard!", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const options = [
    {
      label: "Include Uppercase (ABC)",
      state: useUppercase,
      setState: setUseUppercase,
    },
    {
      label: "Include Lowercase (abc)",
      state: useLowercase,
      setState: setUseLowercase,
    },
    {
      label: "Include Numbers (123)",
      state: useNumbers,
      setState: setUseNumbers,
    },
    {
      label: "Include Symbols (#$&)",
      state: useSymbols,
      setState: setUseSymbols,
    },
  ];

  const handleCheckboxChange = (state, setState) => {
    // Prevent unchecking if it's the last selected checkbox
    const checkedCount = [
      useUppercase,
      useLowercase,
      useNumbers,
      useSymbols,
    ].filter(Boolean).length;
    if (checkedCount === 1 && state) return; // Don't allow unchecking if this is the last selected checkbox
    setState(!state);
  };

  // Update password and strength every time the password settings change
  useEffect(() => {
    const newPassword = generatePassword();
    setPassword(newPassword);
    const newStrength = calculateStrength();
    setStrength(newStrength);
  }, [passwordLength, useUppercase, useLowercase, useNumbers, useSymbols]); // Recalculate on these dependencies

  return (
    <>
      <RandomPasswordGeneratorHeader />
      <ToastContainer />
      <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gradient-to-br">
        <div className="bg-white shadow-xl rounded-lg p-8 w-full max-w-2xl">
          <h2 className="text-4xl font-extrabold mb-6 text-center text-purple-700">
            Random Password Generator
          </h2>

          {/* Password Display */}
          <div className="mb-6">
            <div className="flex items-center justify-between mb-4">
              <input
                type="text"
                value={password}
                readOnly
                className="w-full px-4 py-3 text-lg text-gray-900 bg-gray-100 rounded-lg border-2 border-purple-300 shadow-sm"
              />
              <button
                onClick={handleCopyPassword}
                className="ml-4 bg-gradient-to-r from-purple-500 to-purple-700 text-white py-2 px-4 rounded-lg hover:opacity-90 transition-all duration-200"
              >
                Copy
              </button>
            </div>
            <p
              className={clsx("text-lg font-semibold", strengthColor(strength))}
            >
              Strength: {strength}
            </p>
          </div>

          {/* Password Length Slider */}
          <div className="mb-6">
            <label className="block mb-2 font-semibold">Password Length</label>
            <input
              type="range"
              min="4"
              max="50"
              value={passwordLength}
              onChange={(e) => setPasswordLength(Number(e.target.value))}
              className="w-full accent-purple-600"
            />
            <span className="block text-center font-bold mt-2">
              {passwordLength} Characters
            </span>
          </div>

          {/* Options */}
          <div className="grid grid-cols-2 gap-4 mb-6">
            {options.map(({ label, state, setState }) => (
              <div key={label} className="flex items-center">
                <input
                  type="checkbox"
                  checked={state}
                  onChange={() => handleCheckboxChange(state, setState)}
                  className="accent-purple-600"
                />
                <label className="ml-2 text-gray-800">{label}</label>
              </div>
            ))}
          </div>

          {/* Generate Password Button */}
          <button
            onClick={() => setPassword(generatePassword())}
            className="w-full bg-gradient-to-r from-purple-400 to-purple-600 text-white py-3 rounded-lg font-semibold shadow-lg hover:shadow-xl transition-all duration-200"
          >
            Generate Password
          </button>
        </div>
      </div>
    </>
  );
}

export default RandomPasswordGenerator;
