const binaryConverters = [
  { name: "Binary To Hex", id: "btx" },
  { name: "Binary To Decimal", id: "btd" },
  { name: "Binary To Ascii Text", id: "btat" },
  { name: "Binary To Octal", id: "bto" },
];

const hexadecimalConverters = [
  { name: "Hex To Binary", id: "htb" },
  { name: "Hex To Decimal", id: "htd" },
  { name: "Hex To Ascii", id: "htas" },
];

const decimalConverters = [
  { name: "Decimal To Hex", id: "dth" },
  { name: "Decimal To Octal", id: "dto" },
  { name: "Decimal To Binary", id: "dtb" },
];

const asciiTextConverters = [
  { name: "Ascii Text To Binary", id: "atb" },
  { name: "Ascii Text To Decimal", id: "atd" },
  { name: "Ascii Text To Hexadecimal", id: "ath" },
];

const octalConverters = [
  { name: "Octal To Decimal", id: "otd" },
  { name: "Octal To Binary", id: "otb" },
];

const conversionExampleData = {
  btx: {
    title: "Binary to Hexadecimal",
    description:
      "Binary is a base-2 numeral system consisting of two digits: 0 and 1. Hexadecimal, a base-16 system, uses 16 symbols: 0-9 and A-F.",
    example:
      "Example: Convert 1010 (binary) to hexadecimal.<br> 1010 (binary) = A (hexadecimal)",
  },
  btd: {
    title: "Binary to Decimal",
    description:
      "Binary (base-2) is converted to decimal (base-10), the standard numeral system used by humans for everyday counting.",
    example:
      "Example: Convert 1010 (binary) to decimal.<br> 1010 (binary) = 10 (decimal)",
  },
  btat: {
    title: "Binary to ASCII Text",
    description:
      "Binary is mapped to ASCII, a character encoding standard where each group of 8 binary digits (or a byte) represents a single character.",
    example:
      "Example: Convert 01000001 (binary) to ASCII.<br> 01000001 (binary) = 'A' (ASCII)",
  },
  bto: {
    title: "Binary to Octal",
    description:
      "Binary (base-2) is converted to octal (base-8), a system often used in computing to represent binary data in a more compact form.",
    example:
      "Example: Convert 1010 (binary) to octal.<br> 1010 (binary) = 12 (octal)",
  },
  htb: {
    title: "Hexadecimal to Binary",
    description:
      "Hexadecimal (base-16) is converted to binary (base-2), a numeral system that uses only the digits 0 and 1.",
    example: "Example: Convert A (hex) to binary.<br> A (hex) = 1010 (binary)",
  },
  htd: {
    title: "Hexadecimal to Decimal",
    description:
      "Hexadecimal (base-16) is converted to decimal (base-10), a common numeral system.",
    example: "Example: Convert A (hex) to decimal.<br> A (hex) = 10 (decimal)",
  },
  htas: {
    title: "Hexadecimal to ASCII",
    description:
      "Hexadecimal (base-16) is mapped to ASCII, where each pair of hexadecimal digits corresponds to a character.",
    example: "Example: Convert 41 (hex) to ASCII.<br> 41 (hex) = 'A' (ASCII)",
  },
  dth: {
    title: "Decimal to Hexadecimal",
    description:
      "Decimal (base-10) is converted to hexadecimal (base-16), a more compact representation.",
    example:
      "Example: Convert 10 (decimal) to hexadecimal.<br> 10 (decimal) = A (hexadecimal)",
  },
  dto: {
    title: "Decimal to Octal",
    description: "Decimal (base-10) is converted to octal (base-8).",
    example:
      "Example: Convert 10 (decimal) to octal.<br> 10 (decimal) = 12 (octal)",
  },
  dtb: {
    title: "Decimal to Binary",
    description:
      "Decimal (base-10) is converted to binary (base-2), the fundamental numeral system for computers.",
    example:
      "Example: Convert 10 (decimal) to binary.<br> 10 (decimal) = 1010 (binary)",
  },
  atb: {
    title: "ASCII Text to Binary",
    description:
      "ASCII text is converted to binary, with each character represented by its corresponding ASCII code in binary format.",
    example:
      "Example: Convert 'A' (ASCII) to binary.<br> A (ASCII) = 01000001 (binary)",
  },
  atd: {
    title: "ASCII Text to Decimal",
    description:
      "ASCII text is converted to decimal, with each character represented by its corresponding ASCII code in decimal format.",
    example:
      "Example: Convert 'A' (ASCII) to decimal.<br> A (ASCII) = 65 (decimal)",
  },
  ath: {
    title: "ASCII Text to Hexadecimal",
    description:
      "ASCII text is converted to hexadecimal, with each character represented by its corresponding ASCII code in hexadecimal format.",
    example:
      "Example: Convert 'A' (ASCII) to hexadecimal.<br> A (ASCII) = 41 (hexadecimal)",
  },
  otd: {
    title: "Octal to Decimal",
    description:
      "Octal (base-8) is converted to decimal (base-10), the standard number system.",
    example:
      "Example: Convert 12 (octal) to decimal.<br> 12 (octal) = 10 (decimal)",
  },
  otb: {
    title: "Octal to Binary",
    description:
      "Octal (base-8) is converted to binary (base-2), a system widely used in computing.",
    example:
      "Example: Convert 12 (octal) to binary.<br> 12 (octal) = 1010 (binary)",
  },
};

export {
  binaryConverters,
  hexadecimalConverters,
  decimalConverters,
  asciiTextConverters,
  octalConverters,
  conversionExampleData,
};
