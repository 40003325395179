import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { saveAs } from "file-saver";
import Header from "./Dashboard/Header";

const ConvertToJson = () => {
  const [file, setFile] = useState(null);
  const [conversionResult, setConversionResult] = useState(null);

  const supportedFileTypes = [".csv", ".xml", ".yaml", ".yml", ".ini", ".txt"];

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      toast.info(`File "${droppedFile.name}" uploaded successfully.`);
    }
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      toast.info(`File "${uploadedFile.name}" uploaded successfully.`);
    }
    setConversionResult(null);
  };

  const handleConvert = () => {
    if (!file) {
      toast.error("Please upload a file first.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;

      try {
        let result;

        if (file.name.endsWith(".csv")) {
          result = convertCSVToJSON(content);
        } else if (file.name.endsWith(".xml")) {
          result = convertXMLToJSON(content);
        } else if (file.name.endsWith(".yaml") || file.name.endsWith(".yml")) {
          result = convertYAMLToJSON(content);
        } else if (file.name.endsWith(".ini")) {
          result = convertINIToJSON(content);
        } else if (file.name.endsWith(".txt")) {
          result = convertTextToJSON(content);
        } else {
          throw new Error("Unsupported file type.");
        }

        setConversionResult(result);
        toast.success("File converted to JSON successfully!");
      } catch (error) {
        toast.error("Error converting file: " + error.message);
      }
    };

    reader.readAsText(file);
  };

  const convertCSVToJSON = (csv) => {
    const rows = csv.split("\n");
    const headers = rows[0].split(",");
    const json = rows.slice(1).map((row) => {
      const values = row.split(",");
      return headers.reduce((acc, header, index) => {
        acc[header.trim()] = values[index]?.trim() || "";
        return acc;
      }, {});
    });
    return json;
  };

  const convertXMLToJSON = (xml) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, "application/xml");
    return xmlToJson(xmlDoc);
  };

  const xmlToJson = (xml) => {
    let obj = {};
    if (xml.nodeType === 1) {
      if (xml.attributes.length > 0) {
        obj["@attributes"] = {};
        for (let j = 0; j < xml.attributes.length; j++) {
          const attribute = xml.attributes.item(j);
          obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType === 3) {
      obj = xml.nodeValue;
    }
    if (xml.hasChildNodes()) {
      for (let i = 0; i < xml.childNodes.length; i++) {
        const item = xml.childNodes.item(i);
        const nodeName = item.nodeName;
        if (typeof obj[nodeName] === "undefined") {
          obj[nodeName] = xmlToJson(item);
        } else {
          if (!Array.isArray(obj[nodeName])) {
            obj[nodeName] = [obj[nodeName]];
          }
          obj[nodeName].push(xmlToJson(item));
        }
      }
    }
    return obj;
  };

  const convertYAMLToJSON = (yaml) => {
    const jsYaml = require("js-yaml");
    return jsYaml.load(yaml);
  };

  const convertINIToJSON = (ini) => {
    const lines = ini.split("\n");
    const json = {};
    lines.forEach((line) => {
      const [key, value] = line.split("=");
      if (key && value) {
        json[key.trim()] = value.trim();
      }
    });
    return json;
  };

  const convertTextToJSON = (text) => {
    const lines = text.split("\n");
    return lines.map((line, index) => ({ line: index + 1, content: line }));
  };

  const handleDownload = () => {
    const blob = new Blob([JSON.stringify(conversionResult, null, 2)], {
      type: "application/json",
    });
    saveAs(blob, `${file.name.split(".")[0]}.json`);
    toast.success("JSON file downloaded!");
  };

  return (
    <>
      <Header />
      <div className="bg-white min-h-screen p-6">
        <h1 className="text-3xl font-bold text-center text-black mb-6">
          File to JSON Converter
        </h1>

        <div
          className="max-w-md mx-auto bg-white p-6 shadow rounded border-dashed border-2 border-purple-400"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            accept={supportedFileTypes.join(",")}
            onChange={handleFileChange}
            className="hidden"
            id="file-upload"
          />
          <label
            htmlFor="file-upload"
            className="block text-center text-purple-500 cursor-pointer"
          >
            Drag and drop a file here or click to upload
          </label>
          <p className="text-sm text-black mt-2 text-center">
            Supported file types: {supportedFileTypes.join(", ")}
          </p>
        </div>

        <button
          onClick={handleConvert}
          className="mt-6 block mx-auto bg-purple-600 text-white py-2 px-6 rounded hover:bg-purple-700"
        >
          Convert to JSON
        </button>

        {conversionResult && (
          <div className="max-w-md mx-auto bg-white mt-6 p-4 shadow rounded">
            <h2 className="text-lg font-semibold mb-2">Conversion Result</h2>
            <pre className="bg-gray-100 p-2 rounded text-sm overflow-x-auto">
              {JSON.stringify(conversionResult, null, 2)}
            </pre>
            <div className="flex justify-between mt-4">
              <CopyToClipboard
                text={JSON.stringify(conversionResult, null, 2)}
                onCopy={() => toast.success("JSON copied to clipboard!")}
              >
                <button className="bg-purple-600 text-white py-2 px-4 rounded hover:bg-purple-700">
                  Copy JSON
                </button>
              </CopyToClipboard>
              <button
                onClick={handleDownload}
                className="bg-purple-600 text-white py-2 px-4 rounded hover:bg-purple-700"
              >
                Download JSON
              </button>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default ConvertToJson;
