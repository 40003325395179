import React from "react";
import { Link, useLocation } from "react-router-dom";

const ImageHeader = () => {
  const location = useLocation();

  // Function to determine button classes based on active state
  const buttonClasses = (isActive) =>
    `px-4 py-2 text-sm font-medium text-center rounded-lg transition-all duration-300 ${
      isActive
        ? "text-white bg-red-500 shadow-md"
        : "text-gray-800 bg-gradient-to-r from-cyan-200 via-cyan-300 to-cyan-400 hover:bg-gradient-to-br"
    } focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800`;

  // Navigation items
  const navItems = [
    { path: "/image-conversion", name: "Image Conversion" },
  ];

  return (
    <header>
      <nav className="bg-gray-100 border-b border-gray-300 px-6 py-3 dark:bg-teal-700">
        <div className="flex justify-center items-center mx-auto max-w-screen-xl">
          <ul className="flex space-x-6">
            {navItems.map((item) => (
              <li key={item.path}>
                <Link to={item.path}>
                  <button
                    type="button"
                    className={buttonClasses(location.pathname === item.path)}
                  >
                    {item.name}
                  </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default ImageHeader;
