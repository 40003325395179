const initialState = {
  input: { text: "" },
  output: { text: "" },
};

const base64Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INPUT_TEXT":
      return {
        ...state,
        input: {
          ...state.input,
          text: action.payload.text,
        },
      };

    case "SET_OUTPUT_TEXT":
      return {
        ...state,
        output: {
          ...state.output,
          text: action.payload.text,
        },
      };

    default:
      return state;
  }
};

export default base64Reducer;
