import base64Variable from "../base64Variable";

const textToUtf8 = (text) => {
  if (typeof text !== "string") return null;
  return new TextEncoder().encode(text); // Returns a Uint8Array with the UTF-8 encoded text.
};

const textToAscii = (text) => {
  if (typeof text !== "string") {
    return { success: false, result: "", nonAsciiChars: [] };
  }

  const asciiText = [];
  const nonAsciiChars = [];

  for (const char of text) {
    const charCode = char.charCodeAt(0);
    if (charCode <= 127) {
      asciiText.push(char);
    } else {
      nonAsciiChars.push(char);
    }
  }

  return {
    success: nonAsciiChars.length === 0,
    result: asciiText.join(""),
    nonAsciiChars, // Consistent naming for clarity.
  };
};

export const encodeText = (text, targetAlgo) => {
  const output_format = {
    result: null,
    success: false,
    error: null,
    warning: null,
  };

  if (typeof text !== "string") {
    return { ...output_format, error: "Input must be a string." };
  }

  if (targetAlgo === base64Variable.UTF_8) {
    const utf8Bytes = textToUtf8(text);
    if (!utf8Bytes) {
      return { ...output_format, error: "Invalid UTF-8 text input." };
    }
    const encoded = btoa(String.fromCharCode(...utf8Bytes)); // Proper Base64 encoding.
    return { ...output_format, success: true, result: encoded };
  } else if (targetAlgo === base64Variable.URL) {
    const encoded = encodeURIComponent(text);
    return { ...output_format, success: true, result: encoded };
  } else if (targetAlgo === base64Variable.ASCII) {
    const asciiTextConfig = textToAscii(text);
    if (asciiTextConfig.success) {
      return {
        ...output_format,
        success: true,
        result: btoa(asciiTextConfig.result),
      };
    }
    return {
      ...output_format,
      warning: `NOT VALID ASCII CHAR: ${asciiTextConfig.nonAsciiChars.join(
        ", "
      )}`,
      result: btoa(asciiTextConfig.result),
    };
  }
};
