import React, { useState, useCallback, useEffect } from "react";
import AceEditor from "react-ace";
import prettier from "prettier/standalone";
import parserBabel from "prettier/parser-babel";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-monokai";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditorHeader from "./EditorHeader";

// Import Heroicons
import {
  ClipboardIcon,
  TrashIcon,
  ArrowDownTrayIcon,
  ArrowUpIcon,
  WrenchScrewdriverIcon,
  SunIcon,
  MoonIcon,
} from "@heroicons/react/24/outline";

const IconButton = ({
  onClick,
  icon,
  className,
  tooltip,
  disabled,
  loading,
}) => (
  <button
    onClick={onClick}
    disabled={disabled || loading}
    className={`${className} p-2 rounded-lg transition hover:opacity-80 ${loading ? "bg-gray-400" : ""} ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
    aria-label={tooltip}
    title={tooltip}
  >
    {loading ? <span className="loader"></span> : icon}
  </button>
);

function JsCodeEditor() {
  const [jsCode, setJsCode] = useState(
      "/* Add your JavaScript code here */\nconsole.log('Hello, World!');"
  );
  const [fontSize, setFontSize] = useState(14);
  const [theme, setTheme] = useState("chrome");
  const [executionOutput, setExecutionOutput] = useState("");
  const [errorOutput, setErrorOutput] = useState("");
  const [executionTime, setExecutionTime] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
  }, [jsCode]);

  const handleCodeChange = useCallback((newCode) => setJsCode(newCode), []);

  const showError = (msg) => toast.error(msg);
  const showSuccess = (msg) => toast.success(msg);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setJsCode(e.target.result);
        showSuccess("JavaScript file uploaded successfully!");
      };
      reader.onerror = () => showError("Failed to read the file.");
      reader.readAsText(file);
    } else {
      showError("No file selected.");
    }
  };

  const handleDownload = () => {
    const blob = new Blob([jsCode], { type: "application/javascript" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "code.js";
    link.click();
    showSuccess("Download started!");
  };

  const handleClear = useCallback(() => {
    setJsCode("");
    setExecutionOutput("");
    setErrorOutput("");
    setExecutionTime(0);
    showSuccess("Editor cleared.");
  }, []);

  const toggleTheme = () => setTheme(theme === "chrome" ? "monokai" : "chrome");

  const beautifyCode = () => {
    try {
      const formattedCode = prettier.format(jsCode, {
        parser: "babel",
        plugins: [parserBabel],
      });
      setJsCode(formattedCode);
      showSuccess("Code formatted successfully!");
    } catch (error) {
      showError("Failed to format code.");
    }
  };

  const handleRun = useCallback(() => {
    setExecutionOutput("");
    setErrorOutput("");
    setExecutionTime(0);

    const logOutput = [];

    // Define customConsole in the execution context
    const customConsole = {
      log: (...args) => {
        logOutput.push(args.join(" "));
      },
    };

    const start = performance.now();
    setLoading(true);

    try {
      // Sanitize the code by blocking dangerous functions (optional)
      const sanitizedCode = jsCode.replace(
        /document|window|location|fetch|eval|setTimeout|setInterval|clearInterval|clearTimeout/g,
        "/* blocked */"
      );

      // Wrap the code to ensure it runs within a safe context, passing customConsole into the execution
      const codeToExecute = `(function() {
        const console = customConsole; // Define custom console
        ${sanitizedCode} // User code execution
      })();`;

      // Use eval to execute the sanitized code inside the function
      eval(codeToExecute); // Using eval here is safe because we're controlling the execution environment

      const end = performance.now();
      setExecutionTime((end - start).toFixed(2));
      setExecutionOutput(logOutput.join("\n") || "No output");
      setLoading(false);
    } catch (error) {
      setErrorOutput(error.message);
      showError(`Execution error: ${error.message}`);
      setLoading(false);
    }
  }, [jsCode]);

  return (
    <>
      <EditorHeader />

      <main className="h-screen flex flex-col lg:flex-row bg-gray-50">
        <section className="flex-1 flex flex-col border-r">
          <div className="p-4 flex items-center space-x-2">
            <IconButton
              onClick={handleClear}
              icon={<TrashIcon className="h-5 w-5 text-white" />}
              className="bg-red-500 text-white"
              tooltip="Clear Code"
            />
            <label className="bg-blue-500 text-white flex items-center space-x-1 p-2 rounded-lg cursor-pointer hover:opacity-80">
              <ArrowUpIcon className="h-5 w-5" />
              <span></span>
              <input
                type="file"
                accept=".js"
                onChange={handleUpload}
                className="hidden"
              />
            </label>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(jsCode);
                showSuccess("Copied to clipboard!");
              }}
              icon={<ClipboardIcon className="h-5 w-5 text-white" />}
              className="bg-green-500 text-white"
              tooltip="Copy Code"
            />
            <IconButton
              onClick={toggleTheme}
              icon={
                theme === "chrome" ? (
                  <MoonIcon className="h-5 w-5 text-white" />
                ) : (
                  <SunIcon className="h-5 w-5 text-white" />
                )
              }
              className="bg-gray-800 text-white"
              tooltip={
                theme === "chrome"
                  ? "Switch to Dark Mode"
                  : "Switch to Light Mode"
              }
            />
            <input
              type="range"
              min="12"
              max="20"
              value={fontSize}
              onChange={(e) => setFontSize(+e.target.value)}
              className="ml-2"
              aria-label="Font size slider"
            />
          </div>
          <AceEditor
            mode="javascript"
            theme={theme}
            value={jsCode}
            onChange={handleCodeChange}
            name="js-editor"
            editorProps={{ $blockScrolling: true }}
            setOptions={{ showLineNumbers: true, tabSize: 2, useWorker: false }}
            style={{ width: "100%", height: "100%", fontSize }}
          />
        </section>
        <section className="flex-1 flex flex-col">
          <div className="p-4 flex items-center space-x-2">
            <IconButton
              onClick={handleDownload}
              icon={<ArrowDownTrayIcon className="h-5 w-5 text-white" />}
              className="bg-blue-800 text-white"
              tooltip="Download Code"
            />
            <IconButton
              onClick={handleRun}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="gfg-icon"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="2.0"
                  stroke="#2c3e50"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M7 4v16l13 -8z"></path>
                </svg>
              }
              className="bg-yellow-500 text-white"
              tooltip="Run Code"
              loading={loading}
            />
          </div>
          <div className="flex-1 border bg-white rounded-lg p-4 overflow-y-auto">
            <h3 className="font-bold">Output:</h3>
            <pre>{executionOutput || "Run code to see the output here."}</pre>
            <h3 className="font-bold mt-4">Execution Time:</h3>
            <p>{executionTime ? `${executionTime} ms` : "N/A"}</p>
            {errorOutput && (
              <>
                <h3 className="font-bold text-red-500 mt-4">Errors:</h3>
                <pre>{errorOutput}</pre>
              </>
            )}
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
}

export default JsCodeEditor;
