import React, { useState } from "react";
import { toast } from "react-toastify";

const DragDropFile = ({ setText }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (!file || file.type !== "text/plain") {
      toast.error("Please upload a valid text file (.txt)!");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      setText(e.target.result);
      toast.success("File content loaded successfully!");
    };
    reader.readAsText(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleFile(file);
  };

  return (
    <div
      className={`border-2 ${
        isDragging
          ? "border-purple-400 bg-purple-50"
          : "border-purple-300 bg-purple-100"
      } rounded-lg p-4 mb-4 text-center`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <p className="text-purple-700">
        Drag and drop a <strong>.txt</strong> file here, or click to upload.
      </p>
      <input
        type="file"
        accept=".txt"
        onChange={handleFileChange}
        className="hidden"
        id="file-upload"
      />
      <label
        htmlFor="file-upload"
        className="cursor-pointer text-purple-600 underline mt-2"
      >
        Select a file
      </label>
    </div>
  );
};

export default DragDropFile;
