import React, { useState } from "react";
import { JSONTree } from "react-json-tree";
import AceEditor from "react-ace";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";

function JsonPathFinder() {
  const [jsonData, setJsonData] = useState(
    JSON.stringify(
      {
        user: {
          id: 12345,
          name: "John Doe",
          email: "johndoe@example.com",
          address: {
            street: "123 Main St",
            city: "Springfield",
            state: "IL",
            postalCode: "62701",
            country: "USA",
          },
        },
      },
      null,
      2
    )
  );
  const [selectedPath, setSelectedPath] = useState("");
  const [validJson, setValidJson] = useState(true); // Track if the JSON is valid

  // Update JSON input value and validate JSON on each change
  const handleJsonChange = (newValue) => {
    setJsonData(newValue);
    try {
      JSON.parse(newValue); // Try parsing the JSON to check if it's valid
      setValidJson(true); // Set valid if no error
    } catch (error) {
      setValidJson(false); // Set invalid if an error occurs
    }
  };

  // Beautify JSON
  const beautifyJson = () => {
    try {
      const parsed = JSON.parse(jsonData);
      setJsonData(JSON.stringify(parsed, null, 2));
      toast.success("JSON beautified successfully!");
    } catch (error) {
      toast.error("Invalid JSON format. Please correct the errors.");
    }
  };

  // Minify JSON
  const minifyJson = () => {
    try {
      const parsed = JSON.parse(jsonData);
      setJsonData(JSON.stringify(parsed));
      toast.success("JSON minified successfully!");
    } catch (error) {
      toast.error("Invalid JSON format. Please correct the errors.");
    }
  };

  // Handle key path click
  const handleNodeClick = (keyPath) => {
    try {
      const path = keyPath.reverse().join(".");
      setSelectedPath(`$.${path}`);
      toast.info("Path updated!");
    } catch (error) {
      toast.error("An error occurred while selecting the path.");
    }
  };

  // Validate JSON for JSONTree
  const getParsedJson = () => {
    try {
      return JSON.parse(jsonData);
    } catch {
      return {};
    }
  };

  return (
    <div className="h-screen flex bg-gray-50">
      {/* Toast Notifications */}
      <ToastContainer position="top-right" autoClose={3000} />

      {/* Left Panel */}
      <div className="w-1/2 h-screen p-4 bg-white border-r shadow-sm flex flex-col">
        {/* Buttons */}
        <div className="flex space-x-4 mb-4">
          <button
            onClick={beautifyJson}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Beautify
          </button>
          <button
            onClick={minifyJson}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Minify
          </button>
          <button
            className={`px-4 py-2 bg-gray-500 text-white ${
              validJson ? "bg-green-500" : "bg-red-500"
            }`}
          >
            {validJson ? "JSON is Valid" : "JSON is Invalid"}
          </button>
        </div>

        {/* Ace Editor */}
        <div className="flex-grow border rounded">
          <AceEditor
            mode="json"
            theme="github"
            value={jsonData}
            onChange={handleJsonChange}
            name="json-editor"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            width="100%"
            height="100%"
            setOptions={{
              useWorker: false,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              tabSize: 2,
            }}
          />
        </div>
      </div>

      {/* Right Panel */}
      <div className="w-1/2 h-screen p-4 bg-gray-100 shadow-sm flex flex-col">
        {/* Path Display */}
        <div className="flex items-center space-x-2 mb-4">
          <span className="text-gray-600 font-semibold">Path:</span>
          <input
            type="text"
            value={selectedPath}
            readOnly
            className="flex-grow p-2 border rounded bg-white"
          />
          <button
            onClick={() => {
              navigator.clipboard.writeText(selectedPath);
              toast.success("Path copied to clipboard!");
            }}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Copy
          </button>
        </div>

        {/* JSON Tree */}
        <div className="flex-grow overflow-auto border rounded bg-white p-2">
          <JSONTree
            data={getParsedJson()}
            shouldExpandNode={() => true}
            onClick={(_, keyPath) => handleNodeClick(keyPath)}
            theme={{
              scheme: "default",
              base00: "#fdfdfd",
              base03: "#444",
              base09: "#333",
              base0B: "#00a884",
            }}
            invertTheme={false}
          />
        </div>
      </div>
    </div>
  );
}

export default JsonPathFinder;
