import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_right_text_json_validate_action } from "../../../action/json/validator/jsonValidateAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dropDownStyle } from "../../../style/commonStyle";

function JsonFormatButton() {
  const left_screen = useSelector(
    (state) => state.jsonValidateStore?.left_screen || { text: "" }
  );

  const dispatch = useDispatch();

  const [tabSpace, setTabSpace] = useState(4);

  const handleTabSpaceChange = (event) => {
    setTabSpace(parseInt(event.target.value, 10));
  };

  const handleJsonFormat = () => {
    try {
      const leftJson = JSON.parse(left_screen.text);
      const formattedJson = JSON.stringify(leftJson, null, tabSpace);

      dispatch(
        set_right_text_json_validate_action({
          text: formattedJson,
          isValid: true,
        })
      );

      toast.success("JSON formatted successfully!", { autoClose: 3000 });
    } catch (error) {
      dispatch(
        set_right_text_json_validate_action({
          text: `Invalid JSON: ${error.message}`,
          isValid: false,
        })
      );
      toast.error(`Invalid JSON: ${error.message}`, { autoClose: 3000 });
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen space-y-4">
      {/* Container for dropdown and button */}
      <div className="flex items-center space-x-4">
        <select
          id="tabSpace"
          value={tabSpace}
          onChange={handleTabSpaceChange}
          className={`${dropDownStyle}`}
        >
          <option value={2} className="p-2">
            2 Tab Space
          </option>
          <option value={4} className="p-2">
            4 Tab Space
          </option>
          <option value={8} className="p-2">
            8 Tab Space
          </option>
        </select>
        <button
          type="button"
          onClick={handleJsonFormat}
          className="bg-purple-600 text-white hover:bg-purple-700 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 shadow-md transition-all"
        >
          Format/Beautify JSON
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default JsonFormatButton;
