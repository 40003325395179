import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Base64Header from "../dashboard/Base64Header";
import Base64Form from "../dashboard/Base64Form";
import Base64ActionBoard from "../dashboard/Base64ActionBoard";
import Base64EncodingToolBar from "../toolbar/Base64EncodingToolBar";

import {
  set_input_text_base64_action,
  set_output_text_base64_action,
} from "../../../action/base64/base64Action";

const Base64Dashboard = ({ button_name, ACTION_TYPE }) => {
  const dispatch = useDispatch();
  const { input, output } = useSelector((state) => state.base64Store);

  // Clear input and output on component load or action type change
  useEffect(() => {
    dispatch(set_input_text_base64_action({ text: "" }));
    dispatch(set_output_text_base64_action({ text: "" }));
  }, [dispatch, button_name, ACTION_TYPE]);

  // Handler for input change
  const handleInputChange = useCallback(
    (newContent) =>
      dispatch(set_input_text_base64_action({ text: newContent })),
    [dispatch]
  );

  // Common clipboard handler
  const handleCopy = useCallback((text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success("COPIED"))
      .catch(() => toast.error("Error copying text"));
  }, []);

  // Common download handler
  const handleDownload = useCallback((text, filename) => {
    const blob = new Blob([text], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = filename;
    anchor.click();
    URL.revokeObjectURL(url); // Clean up
  }, []);

  const handleUpload = useCallback(
    (event) => {
      const file = event.target.files[0]; // Get the uploaded file
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const newContent = e.target.result; // Read the file content
          dispatch(set_input_text_base64_action({ text: newContent })); // Dispatch the content to Redux
          toast.success("File uploaded successfully!");
        };
        reader.onerror = () => {
          toast.error("Error reading the file!");
        };
        reader.readAsText(file); // Read the file as plain text
      }
    },
    [dispatch]
  );

  // Common props for Base64Form
  const commonFormProps = {
    defaultHeight: 300,
    defaultWidth: 800,
  };

  return (
    <>
      <Base64Header />
      <ToastContainer />
      <div className="flex flex-col justify-center items-center text-center my-10">
        {/* Toolbar for Input */}
        <Base64EncodingToolBar
          width={800}
          height={30}
          onCopy={() => handleCopy(input.text)}
          onDownload={() => handleDownload(input.text, "input.txt")}
          onTextFileUpload={handleUpload}
          onImageFileUpload={null}
        />
        {/* Input Form */}
        <Base64Form
          {...commonFormProps}
          value={input.text}
          onChange={handleInputChange}
        />
        {/* Action Board */}
        <Base64ActionBoard
          config={{
            button_name,
            ACTION_TYPE,
            width: 800,
            height: 160,
          }}
        />
        {/* Toolbar for Output */}
        <Base64EncodingToolBar
          width={800}
          height={30}
          active_image_upload_file_button={false}
          active_upload_text_file_button={false}
          onCopy={() => handleCopy(output.text)}
          onDownload={() => handleDownload(output.text, "output.txt")}
        />
        {/* Output Form */}
        <Base64Form {...commonFormProps} readOnly value={output.text} />
      </div>
    </>
  );
};

export default Base64Dashboard;
